import React, {
  useRef, useEffect, useState, useMemo
} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Dialog from 'components/dialog/common'
import HandleRoutes from 'layouts/officialWebsite/handleRoutes'
import AlertDialog from 'components/alertDialog/common'
import ArticleDialog from 'components/articleDialog/common'
import ConfirmDialog from 'components/confirmDialog/common'
import R18AlertDialog from 'components/r18AlertDialog/common'
import VideoDialog from 'components/videoDialog/common'
import Rate18Alert from 'components/rate18Alert/mobile'
import InfoDialog from 'components/infoDialog/common'
import theme from 'theme/desktop/muiTheme'
// import Header from 'views/header/mobile'
import HandleHeader from 'layouts/officialWebsite/mobile/sub/handleHeader'
import HandleFooter from 'layouts/officialWebsite/mobile/sub/handleFooter'
import { Switch } from 'react-router-dom'
import routes from 'router/officialWebsite'
// import BottomNavigation from 'views/bottomNavigation/mobile'
import HandleNavigation from 'layouts/officialWebsite/handleNavigation'
import HandleToTop from 'layouts/officialWebsite/handleToTop'
import HandleFB from 'layouts/officialWebsite/handleFB'
import PageDialog from 'components/pageDialog/common'
import FullScreenBackdrop from 'components/fullScreenBackdrop/common'
import { getCookie } from 'utils/cookie'
import useStore from 'zustand/r18AlertDialog'
import clsx from 'clsx'
import styles from './layout.module.sass'

function Layout() {
  const isMounted = useRef(false)
  const [showHint, setShowHint] = useState(true) // 顯示18禁提示框
  const { setR18AlertDialog } = useStore()

  const showR18AlertDialog = useMemo(() => {
    setR18AlertDialog({ open: true, component: () => <Rate18Alert setShowHint={setShowHint} /> })
  }, [setR18AlertDialog])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      const checkLevelCookie = getCookie('checkLevel')
      if (checkLevelCookie) {
        setShowHint(false)
        setR18AlertDialog({ open: false })
      }
    }
  }, [setR18AlertDialog])

  useEffect(() => {
    if (showHint) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'overlay'
    }
  }, [showHint])

  const view = useMemo(() => (
    <>
      <HandleHeader />
      <Switch>
        {routes('isMobile').map((route, i) => (
          <HandleRoutes
            key={route.path}
            {...route}
          />
        ))}
      </Switch>
      <HandleToTop />
      <HandleNavigation />
      <HandleFooter />
    </>
  ), [])

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.root}>
        <HandleFB showHint={showHint} />
        {showHint && (
          showR18AlertDialog
        )}
        <div className={clsx(styles.view, showHint && styles.vague)}>
          {view}
        </div>
        <InfoDialog />
        <AlertDialog />
        <ConfirmDialog />
        <ArticleDialog />
        <PageDialog />
        <Dialog />
        <R18AlertDialog />
        <VideoDialog />
        <FullScreenBackdrop />
      </div>
    </ThemeProvider>
  )
}
export default Layout
