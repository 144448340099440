import React from 'react'

const component = {
  home: React.lazy(() => import('views/home/mobile')),
  news: React.lazy(() => import('views/news/mobile')),
  gameIntro: React.lazy(() => import('views/gameIntro/mobile')),
  vipIntro: React.lazy(() => import('views/vipIntro/mobile')),
  giftIntro: React.lazy(() => import('views/giftIntro/mobile')),
  activityIntro: React.lazy(() => import('views/activityIntro/mobile')),
  mascotIntro: React.lazy(() => import('views/mascotIntro/mobile')),
  propsIntro: React.lazy(() => import('views/propsIntro/mobile')),
  deposit: React.lazy(() => import('views/deposit/mobile')),
  passbook: React.lazy(() => import('views/passbook/mobile')),
  modifyPassword: React.lazy(() => import('views/modifyPassword/mobile')),
  rank: React.lazy(() => import('views/rank/mobile')),
  question: React.lazy(() => import('views/question/mobile')),
  rightsDescription: React.lazy(() => import('views/rightsDescription/mobile')),
  serviceDetail: React.lazy(() => import('views/serviceDetail/mobile')),
  suspenseList: React.lazy(() => import('views/suspenseList/mobile')),
  article: React.lazy(() => import('views/article/mobile')),
  notFound: React.lazy(() => import('views/notFound/mobile')),
  fbCustomer: React.lazy(() => import('views/fbCustomer/mobile')),
  intro: React.lazy(() => import('views/intro/mobile')),
  probabilityDetail: React.lazy(() => import('views/probabilityDetail/mobile')),
  activity: React.lazy(() => import('views/activity/mobile')),
  activityPage: React.lazy(() => import('views/activityPage')),
  deregisterAccount: React.lazy(() => import('views/deregisterAccount/mobile')),
  externalGamesRebate: React.lazy(() => import('views/externalGamesRebate/mobile')),
}

export default component
