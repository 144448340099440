import { getServerTimeApi } from 'api/service'
import errorHandler from 'utils/errorHandler'

export default categoryID => async (dispatch) => {
  try {
    const serverTime = await getServerTimeApi()
    return serverTime
  } catch (error) {
    return errorHandler(error)
  }
}
