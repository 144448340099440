import React from 'react'
import loadingImage from 'image/loading.png'
import styles from './loadingBlock.module.sass'

function LoadingBlock() {
  return (
    <div className={styles.root}>
      <img
        className={styles.loadingImage}
        src={loadingImage}
        alt="loading"
      />
    </div>
  )
}

export default LoadingBlock
