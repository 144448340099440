import React from 'react'
import UserInfo from 'components/userInfo/common'
import Logo from 'components/logo/common'
import MainMenu from 'components/mainMenu/desktop'
import clsx from 'clsx'
import styles from './headerSubPage.module.sass'

function Header() {
  return (
    <div className={styles.root}>
      <div className={clsx(styles.toolbar)}>
        <div className={styles.leftTrack} />
        <div className={styles.centerContent}>
          <div className={styles.headerContent}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.toolbarItems}>
              <MainMenu />
              <UserInfo />
            </div>
          </div>
        </div>
        <div className={styles.rightTrack} />
        <div className={clsx(styles.headerBackground)} />
      </div>
    </div>

  )
}

export default Header
