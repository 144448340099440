import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import DOMPurify from 'dompurify'
import getGameInfoService from 'services/article/getGameInfo'
import styles from 'layouts/appView/layout.module.sass'
import { useParams } from 'react-router-dom'

function GameInfo() {
  const { gameId } = useParams()
  const dispatch = useDispatch()
  const [content, setContent] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await dispatch(getGameInfoService(gameId))
        setContent(DOMPurify.sanitize(data.content))
      } catch (error) {
        //
      }
    }
    fetchData()
  }, [dispatch, gameId])

  return (
    <div
      className={styles.innerHtml}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: content }}
    />

  )
}

export default GameInfo
