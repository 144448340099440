import $axios, { $axiosCdn } from 'utils/axios'

const service = {
  getSuspenseListApi: async (payload) => {
    const { data } = await $axiosCdn({
      method: 'get',
      url: '/Web/SuspensionList/SuspensionList.json',
    })
    return data.memberList
  },
  getServerTimeApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/service/serverTime',
    })
    return data
  },
}
export const { getSuspenseListApi, getServerTimeApi } = service
export default service
