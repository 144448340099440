import { configureStore } from '@reduxjs/toolkit'
import auth from '../auth/slice'
import system from '../system/slice'
import member from '../member/slice'
import rank from '../rank/slice'
import suspenseList from '../suspenseList/slice'

export default configureStore({
  reducer: {
    auth,
    system,
    member,
    rank,
    suspenseList,
  },
})
