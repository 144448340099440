import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  pageDialog: {
    open: false,
    title: '',
    apiName: '',
  },
  articleDialog: {
    open: false,
    categoryID: 0,
    articleID: 0,
    showPrevNextButton: true,
    showShareButton: true,
  },
}
const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    modifyPageDialog: (state, action) => {
      state.pageDialog.open = action.payload.open
      state.pageDialog.title = action.payload.title
      state.pageDialog.apiName = action.payload.apiName
    },
    modifyArticleDialog: (state, action) => {
      state.articleDialog.open = action.payload.open
      state.articleDialog.categoryID = action.payload.categoryID
      state.articleDialog.articleID = action.payload.articleID
      state.articleDialog.showPrevNextButton = action.payload.showPrevNextButton
      state.articleDialog.showShareButton = action.payload.showShareButton
    },
  },
})

export const {
  modifyArticleDialog, modifyPageDialog,
} = systemSlice.actions
export default systemSlice.reducer
