import React from 'react'
import Sketch from 'react-p5'
import Star from 'views/galaxy/js/start'
import PropTypes from 'prop-types'
import { getRandom } from 'utils/common'
import styles from './galaxy.module.sass'

const stars = []
const CANVAS_HEIGHT = 2300

function Galaxy(props) {
  const { starCount, color } = props
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, CANVAS_HEIGHT).parent(canvasParentRef)

    for (let i = 0; i < starCount; i++) {
      const renderColor = {
        r: color.r || 150, g: color.g || 150, b: color.b || 255, a: getRandom(150, 255),
      }
      const starSize = { min: 0.75, max: 1.75 }
      stars[i] = new Star(p5, p5.windowWidth, CANVAS_HEIGHT, renderColor, starSize)
    }
  }

  const draw = (p5) => {
    p5.clear()
    p5.background('#0000')
    for (let i = 0; i < stars.length; i++) {
      stars[i].draw()
    }
  }
  return <Sketch className={styles.root} setup={setup} draw={draw} />
}

export default Galaxy

Galaxy.propTypes = {
  starCount: PropTypes.number,
  color: PropTypes.object,
}
Galaxy.defaultProps = {
  starCount: 0,
  color: {},
}
