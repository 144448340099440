import CurrencyFormat from 'react-currency-format'
import React from 'react'

export default function handleTdFelid(bodyItem, headerItem, data) {
  const { apiTdName } = headerItem
  if (headerItem.currency) {
    return <CurrencyFormat displayType="text" value={bodyItem[apiTdName]} thousandSeparator />
  }
  if (headerItem.tdComponent) {
    return <headerItem.tdComponent apiItem={bodyItem} data={data} />
  }
  // 因為外接遊戲的機台編號可能為負數，所以當為負數時，顯示為 '-'
  if (apiTdName === 'machineNumber') {
    const value = bodyItem[apiTdName]
    if (value === undefined || value === null || value === '') {
      return '-'
    }
    const parsedValue = parseInt(bodyItem[apiTdName], 10)
    if (Number.isNaN(parsedValue) || parsedValue < 0) {
      return '-'
    }
    return parsedValue
  }

  return bodyItem[apiTdName]
}
