const validation = {
  requiredMsg: '必填',
  account: {
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/i,
    msg: '皆為英文+數字(6~12碼)',
  },
  password: {
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/i,
    msg: '皆為英文+數字(6~12碼)',
  },
  phone: {
    pattern: /^09\d{2}-?\d{3}-?\d{3}$/,
    msg: '請輸入有效的台灣手機號碼',
  },
  email: {
    pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    msg: 'Email格式錯誤',
  },
  sms: {
    pattern: /\d{6}/,
    msg: '須為6個數字',
  },
  donateCode: {
    pattern: /^[0-9]{3,7}$/,
    msg: '捐贈碼最少3碼，最多7碼數字',
  },
  moicaNumber: {
    pattern: /^[A-Z]{2}[0-9]{14}$/,
    msg: '自然人憑證：2碼大寫英文字碼+14碼數字之編號',
  },
  cellphoneVehicleNumber: {
    pattern: /^\/[A-Z0-9+-.]{7}$/,
    msg: '手機條碼格式為1碼斜線「/」加上由7碼數字及大寫英文字母及+-.符號',
  },
  taxID: { // 統一編號
    pattern: /^[0-9]{8}$/,
    msg: '統一編號為8碼數字',
  },
  cardID: { // 儲值卡序號
    pattern: /^[a-zA-Z0-9\-&_]{12}$|^[a-zA-Z0-9\-&_]{16}$/,
    msg: '儲值卡序號格式錯誤',
  },
  cardPW: { // 儲值卡密碼
    pattern: /^[a-zA-Z0-9\-&_]{12}$|^[a-zA-Z0-9\-&_]{16}$/,
    msg: '儲值卡密碼格式錯誤',
  },
  serialNum: { // 產包序號
    pattern: /^[a-zA-Z0-9\-&_]{12}$|^[a-zA-Z0-9\-&_]{16}$/,
    msg: '序號格式錯誤',
  },
  serialPwd: { // 產包密碼
    pattern: /^[a-zA-Z0-9\-&_]{12}$|^[a-zA-Z0-9\-&_]{16}$/,
    msg: '密碼格式錯誤',
  },
}

export const {
  requiredMsg, account, password,
  phone, sms, donateCode,
  moicaNumber, cellphoneVehicleNumber,
  taxID, email,
  cardID, cardPW,
  serialNum, serialPwd,
} = validation
export default validation
