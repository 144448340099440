import { forgetPasswordApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'

export default data => dispatch => new Promise((resolve, reject) => {
  const doForgotPassword = async reToken => resolve(await forgetPasswordApi({ reToken, ...data }))
  try {
    return window.reCaptchaV2Invisible.execute(doForgotPassword)
  } catch (error) {
    reject(error)
    return errorHandler(error)
  }
})
