import React, {
  useState, useEffect, Suspense, useRef, useCallback
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { v4 as uuid } from 'uuid'
import checkLoginService from 'services/auth/checkLogin'

function HandleRoutes(route) {
  const {
    // path, isAuthenticated, exact, component, routes,
    path, isAuthenticated, exact, routes,

  } = route
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(state => state.auth)
  const [checkAuthSuccess, setCheckAuthSuccess] = useState(false)
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    async function checkAuth() {
      const result = await dispatch(checkLoginService())
      if (isMounted.current && result) {
        setCheckAuthSuccess(true)
      }
    }
    checkAuth()
    return () => { isMounted.current = false }
  }, [dispatch])

  const RenderRoutes = useCallback(() => {
    if (checkAuthSuccess) {
      if (isAuthenticated && !isLoggedIn) {
        return <Redirect to={{ pathname: '/', state: { openLoginDialog: !isLoggedIn } }} />
      }
      return (
        <Suspense fallback={<div style={{ height: '100vh' }}>Loading...</div>}>
          {/* <Route
            key={uuid()}
            path={path}
            exact={exact}
            component={component}
            routes={routes}
          /> */}
          <Route
            path={path}
            key={uuid()}
            exact={exact}
            render={props => (
              // pass the sub-routes down to keep nesting
              <route.component {...props} routes={routes} />
            )}
          />
        </Suspense>
      )
    }
    return null
    // }, [checkAuthSuccess, component, exact, isAuthenticated, isLoggedIn, path, routes])
  }, [checkAuthSuccess, exact, isAuthenticated, isLoggedIn, path, routes])

  return (
    <RenderRoutes />
  )
}

export default HandleRoutes
