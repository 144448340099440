import { verifyPhoneApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'

export default data => dispatch => new Promise((resolve, reject) => {
  const doVerifyPhone = async reToken => resolve(await verifyPhoneApi({ reToken, ...data }))
  try {
    return window.reCaptchaV2Invisible.execute(doVerifyPhone)
  } catch (error) {
    reject(error)
    return errorHandler(error)
  }
})
