import React from 'react'
import { Dialog as MuiDialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styles from 'components/dialog/common/dialog.module.sass'
import Title from 'components/dialog/common/sub/title'
import useStore from 'zustand/dialog'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function Dialog() {
  const dialog = useStore(state => state.dialog)
  const { setDialog } = useStore()

  function handleOnClose() {
    if (dialog.handleOnClose) {
      dialog.handleOnClose()
    }
    setDialog({ open: false })
  }
  function selectComponent() {
    if (!dialog.component) {
      return (
        <>
          <Title title={dialog.title} />
          <div className={styles.content}>
            <div className={styles.text}>
              {dialog.content}
            </div>
          </div>
        </>
      )
    }
    return <dialog.component />
  }

  return (
    <MuiDialog
      maxWidth={false}
      TransitionComponent={Transition}
      open={dialog.open}
      onClose={() => { handleOnClose() }}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        {selectComponent()}
      </div>
    </MuiDialog>
  )
}

export default Dialog
