import React, {
  useState, useEffect, useRef, useCallback
} from 'react'
import DOMPurify from 'dompurify'
import Slide from '@material-ui/core/Slide'
import LoadingBackdrop from 'components/loadingBackdrop/common'
import { Dialog as MuiDialog } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { modifyArticleDialog } from 'redux/system/slice'
import getNewsService from 'services/article/getNews'
import getNewsListService from 'services/article/getNewsList'
import Scrollbar from 'components/scrollbar/common'
import ShareButtonGroup from 'components/shareButtonGroup/common'
import styles from './articleDialog.module.sass'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function ArticleDialog() {
  const dispatch = useDispatch()
  const { articleDialog } = useSelector(state => state.system)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [curList, setCurList] = useState([])
  const [articleURL, setArticleURL] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useRef(false)

  useEffect(() => {
    if (articleDialog.showPrevNextButton) {
      const getCurList = async () => {
        if (articleDialog.open) {
          const rs = await dispatch(getNewsListService({
            categoryID: articleDialog.categoryID,
            offset: 0,
            limit: 0,
          }))
          setCurList(rs.news)
        }
      }
      getCurList()
    }
  }, [articleDialog.showPrevNextButton, dispatch, articleDialog.open, articleDialog.categoryID])

  useEffect(() => {
    isMounted.current = true
    if (articleDialog.open) {
      setIsLoading(true)
      const getArticle = async () => {
        try {
          const rs = await dispatch(getNewsService({ id: articleDialog.articleID }))
          if (isMounted.current) {
            setTitle(rs.title)
            setContent(DOMPurify.sanitize(rs.content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow', 'target'] }))
            setArticleURL(`${window.location.protocol}//${window.location.hostname}/article/${articleDialog.articleID}`)
            setIsLoading(false)
          }
        } catch (error) {
          //
        }
      }
      getArticle()
    }
    return () => { isMounted.current = false }
  }, [dispatch, articleDialog.open, articleDialog.articleID])

  const toArticle = useCallback((action) => {
    if (curList) {
      const curIndex = curList.findIndex(cur => cur.id === articleDialog.articleID)
      const toIndex = (curIndex !== undefined) ? (action === 'PREV' ? curIndex - 1 : curIndex + 1) : -1
      const toID = (toIndex >= 0 && toIndex < curList.length) ? curList[toIndex].id : -1
      if (toID !== -1) {
        dispatch(modifyArticleDialog({
          open: true,
          categoryID: articleDialog.categoryID,
          articleID: toID,
          showPrevNextButton: articleDialog.showPrevNextButton,
          showShareButton: articleDialog.showShareButton,
        }))
      }
    }
  }, [curList,
    articleDialog.articleID,
    articleDialog.categoryID,
    articleDialog.showPrevNextButton,
    articleDialog.showShareButton,
    dispatch])

  const closeDialog = () => { dispatch(modifyArticleDialog({ open: false })) }

  return (
    <MuiDialog
      fullWidth
      scroll="paper"
      TransitionComponent={Transition}
      open={articleDialog.open}
      onClose={closeDialog}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>
          <div className={styles.scrollbarFrame}>
            <Scrollbar>
              <div className={styles.scrollbarContent}>
                <div
                  className={styles.innerHtml}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </Scrollbar>
          </div>
          <LoadingBackdrop isLoading={isLoading} />
        </div>
        <div className={styles.functionFooter}>
          {articleDialog.showPrevNextButton
            && (
              <div className={styles.pageBtnGroup}>
                <button
                  label="上一則"
                  type="button"
                  className={styles.prevBtn}
                  onClick={toArticle.bind(this, 'PREV')}
                >
                  <div className={styles.prevArrow} />
                  <div className={styles.btnText}>上一則</div>
                </button>
                <button
                  label="下一則"
                  type="button"
                  className={styles.nextBtn}
                  onClick={toArticle.bind(this, 'NEXT')}
                >
                  <div className={styles.btnText}>下一則</div>
                  <div className={styles.nextArrow} />
                </button>
              </div>
            )}
          {articleDialog.showShareButton && (
            <div className={styles.shareButtonGroup}>
              <ShareButtonGroup articleURL={articleURL} iconMode />
            </div>
          )}
          <div
            type="button"
            aria-hidden="true"
            className={styles.closeBtn}
            onClick={closeDialog}
          >
            <div className={styles.closeText}>關閉</div>
            <div className={styles.closeIcon} />
          </div>
        </div>

      </div>
    </MuiDialog>
  )
}

export default ArticleDialog
