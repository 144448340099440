import { loginByAppleApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'
import { login, setTrustDevice } from 'redux/auth/slice'
import { memberInfoApi } from 'api/member'
import handleBindingPhone from 'utils/auth/handleBindingPhone'
import handleNeedBindTrustDevice from 'utils/auth/handleNeedBindTrustDevice'
import checkIsCoolingPeriod from 'utils/checkIsCoolingPeriod'

export default data => async (dispatch) => {
  try {
    await dispatch(setTrustDevice({ loginType: 'APPLE' }))
    const result = await loginByAppleApi(data)
    if (result && result.needBindTrustDevice) {
      await handleNeedBindTrustDevice(result)
    } else {
      await dispatch(login())
      const memberInfoData = await memberInfoApi()
      await handleBindingPhone(memberInfoData, dispatch)
      checkIsCoolingPeriod(result)
    }
  } catch (error) {
    errorHandler(error)
  }
}
