import React from 'react'
import PropTypes from 'prop-types'
import styles from './textInput.module.sass'

function TextInput(props) {
  const {
    label, value, type, name, placeholder, maxLength, autoComplete, handleOnChange,
  } = props

  return (
    <div className={styles.root}>
      {label && (
        <div className={styles.label}>
          <span>{label}</span>
        </div>
      )}
      <input
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        name={name || undefined}
        value={value}
        autoComplete={autoComplete || undefined}
        onChange={handleOnChange}
      />
    </div>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.bool,
  value: PropTypes.string,
  handleOnChange: PropTypes.func,
}

TextInput.defaultProps = {
  label: null,
  type: 'text',
  name: '',
  placeholder: '',
  maxLength: null,
  autoComplete: false,
  value: '',
  handleOnChange: () => { },
}
export default TextInput
