import { renewApi } from 'api/auth'
import { login } from 'redux/auth/slice'
import { getCookie } from 'utils/cookie'

export default () => async (dispatch) => {
  try {
    const memberIdCookie = getCookie('MemberId')
    if (memberIdCookie && memberIdCookie !== '' && memberIdCookie !== 'undefined') {
      await renewApi()
      await dispatch(login())
    }
    return true
  } catch (error) {
    return true
  }
}
