import React from 'react'

// ** import assets
import styles from 'views/appView/externalGamesRebate/style.module.sass'

// ** import shared components
import TextInput from 'components/appView/textInput'
import Table from 'components/appView/table'

// ** import hooks
import usePage from 'hook/externalGame/usePage'

import LoadingBlock from './components/loadingBlock'
import ErrorMessageBlock from './components/errorMessageBlock'
import TitleBar from './components/titleBar'

function ExternalGamesRebate() {
  const [
    memberId,
    result,
    errorData,
    eventSearchStatus,
    handleSearchButtonClick,
    handleMemberIdChange,
  ] = usePage()

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        {eventSearchStatus.done
          ? eventSearchStatus.gameDisplayName ? (
            <>
              <TitleBar
                type={eventSearchStatus.type}
                title={eventSearchStatus.gameDisplayName}
                startTime={eventSearchStatus.startTime}
                endTime={eventSearchStatus.endTime}
              />
              <div className={styles.searchBar}>
                <div className={styles.textContainer}>
                  <TextInput
                    label="會員ID"
                    type="text"
                    value={memberId}
                    placeholder="請輸入會員 ID"
                    handleOnChange={handleMemberIdChange}
                  />
                  {errorData.isError && <p className={styles.errorMsg}>{errorData.msg}</p>}
                </div>
                <button
                  type="button"
                  className={styles.button}
                  onClick={handleSearchButtonClick}
                >
                  搜尋
                </button>
              </div>
              <div className={styles.resultBar}>
                <div className={styles.resultBarChild}>
                  <h5 className={styles.label} htmlFor="member-id">{`${eventSearchStatus.accumulationUnit}（金幣）`}</h5>
                  <p>{result.accumulatedBet}</p>
                </div>
                <div className={styles.resultBarChild}>
                  <h5 className={styles.label} htmlFor="member-id">獎勵回饋百分比</h5>
                  <p>{result.percentage}</p>
                </div>
                <div className={styles.resultBarChild}>
                  <h5 className={styles.label} htmlFor="member-id">可獲得金幣量</h5>
                  <p>{result.earnedCoins}</p>
                </div>
              </div>
              <Table
                headerList={eventSearchStatus.tableHeader}
                bodyList={eventSearchStatus.table}
              />
            </>
          ) : (
            <ErrorMessageBlock />
          ) : <LoadingBlock />}
      </div>
    </div>
  )
}

export default ExternalGamesRebate
