import mobileComponent from 'router/officialWebsite/mobile'
import desktopComponent from 'router/officialWebsite/desktop'
import mobileComponentActivities221025 from 'views/activity/router/mobile'
import desktopComponentActivities221025 from 'views/activity/router/desktop'

const routes = (isMobile) => {
  const component = isMobile ? mobileComponent : desktopComponent
  const componentActivity = isMobile ? mobileComponentActivities221025
    : desktopComponentActivities221025

  return [
    {
      path: '/',
      component: component.home,
      exact: true,
    },
    {
      path: '/news',
      component: component.news,
      exact: true,
      pathName: 'news',

    },
    {
      path: '/gameIntro',
      component: component.gameIntro,
      exact: true,
    },
    {
      path: '/vipIntro',
      component: component.vipIntro,
      exact: true,
    },
    {
      path: '/fbCustomer',
      component: component.fbCustomer,
      exact: true,
    },
    {
      path: '/giftIntro',
      component: component.giftIntro,
      exact: true,
    },
    {
      path: '/activityIntro',
      component: component.activityIntro,
      exact: true,
    },
    {
      path: '/mascotIntro',
      component: component.mascotIntro,
      exact: true,
    },
    {
      path: '/propsIntro',
      component: component.propsIntro,
      exact: true,
    },
    {
      path: '/deposit',
      component: component.deposit,
      exact: true,
      isAuthenticated: true,
    },
    {
      path: '/passbook',
      component: component.passbook,
      exact: true,
      isAuthenticated: true,
    },
    {
      path: '/modifyPassword',
      component: component.modifyPassword,
      exact: true,
      isAuthenticated: true,
    },
    {
      path: '/rank',
      component: component.rank,
      exact: true,
    },
    {
      path: '/question',
      component: component.question,
      exact: true,
    },
    {
      path: '/suspenseList',
      component: component.suspenseList,
      exact: true,
    },
    {
      path: '/rightsDescription',
      component: component.rightsDescription,
      exact: true,
    },
    {
      path: '/serviceDetail',
      component: component.serviceDetail,
      exact: true,
    },
    {
      path: '/probabilityDetail',
      component: component.probabilityDetail,
      exact: true,
    },
    {
      path: '/article/:id',
      component: component.article,
      exact: true,
    },
    {
      path: '/intro',
      component: component.intro,
      exact: true,
    },
    {
      path: '/activity221025',
      component: component.activity,
      // exact: true,
      routes: [
        {
          path: '/activity221025/activity1',
          component: componentActivity.activity1,
          exact: true,
        },
        {
          path: '/activity221025/activity2',
          component: componentActivity.activity2,
          exact: true,
        },
        {
          path: '/activity221025/activity3',
          component: componentActivity.activity3,
          exact: true,
        },
        {
          path: '/activity221025/activity4',
          component: componentActivity.activity4,
          exact: true,
        },
        {
          path: '/activity221025/activity5',
          component: componentActivity.activity5,
          exact: true,
        },
        {
          path: '/activity221025/activity6',
          component: componentActivity.activity6,
          exact: true,
        },
        {
          path: '/activity221025/activity7',
          component: componentActivity.activity7,
          exact: true,
        },
        {
          path: '/activity221025/activity8',
          component: componentActivity.activity8,
          exact: true,
        },
        {
          path: '/activity221025/activity9',
          component: componentActivity.activity9,
          exact: true,
        },
        {
          path: '/activity221025/activity10',
          component: componentActivity.activity10,
          exact: true,
        },
      ],
    },
    {
      path: '/activity230103',
      component: component.activityPage,
    },
    {
      path: '/deregisterAccount',
      component: component.deregisterAccount,
      exact: true,
    },
    {
      path: '/external-games/rebate',
      component: component.externalGamesRebate,
      exact: true,
    },
    {
      path: '*',
      component: component.notFound,
      exact: true,
    },
  ]
}

export default routes
