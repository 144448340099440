import React from 'react'

const component = {
  activity1: React.lazy(() => import('views/activity/activities/activity1/mobile')),
  activity2: React.lazy(() => import('views/activity/activities/activity2/mobile')),
  activity3: React.lazy(() => import('views/activity/activities/activity3/mobile')),
  activity4: React.lazy(() => import('views/activity/activities/activity4/mobile')),
  activity5: React.lazy(() => import('views/activity/activities/activity5/mobile')),
  activity6: React.lazy(() => import('views/activity/activities/activity6/mobile')),
  activity7: React.lazy(() => import('views/activity/activities/activity7/mobile')),
  activity8: React.lazy(() => import('views/activity/activities/activity8/mobile')),
  activity9: React.lazy(() => import('views/activity/activities/activity9/mobile')),
  activity10: React.lazy(() => import('views/activity/activities/activity10/mobile')),
}

export default component
