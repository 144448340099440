import create from 'zustand'

const useStore = create((set, get) => ({
  dialog: {
    open: false,
    title: '',
    content: '',
    apiData: {},
    data: {},
    handleOnClose: () => { },
    component: () => null,
    state: null,
  },
  setDialog: dialog => set({ dialog }),
  closeDialog: () => {
    const { dialog } = get()
    console.log('dialog::', dialog)
    if (dialog.handleOnClose) {
      dialog.handleOnClose()
    }
    set({ dialog: { open: false } })
  },
}))
export default useStore
