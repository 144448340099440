import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useFullScreenBackdropStore from 'zustand/fullScreenBackdrop'
import Backdrop from '@material-ui/core/Backdrop'
import clsx from 'clsx'
import styles from './fullScreenBackdrop.module.sass'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'fixed',
    zIndex: 2147483646, // need to higher than dialog
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 20%, rgba(0,0,0,0.8) 50%, rgba(0,0,0,0.6) 80%, rgba(0,0,0,0) 100%)',
  },
}))

function FullScreenBackdrop() {
  const fullScreenBackdrop = useFullScreenBackdropStore(state => state.fullScreenBackdrop)
  const classes = useStyles()
  function selectComponent() {
    if (fullScreenBackdrop.component) {
      return (
        <>
          <div className={styles.component}>
            {fullScreenBackdrop.component}
          </div>
        </>
      )
    } if (fullScreenBackdrop.content) {
      return (
        <>
          <div className={styles.content}>
            {fullScreenBackdrop.content}
          </div>
        </>
      )
    }

    return <div className={styles.loadingImg} />
  }
  return (
    <Backdrop
      className={clsx(classes.backdrop, styles.root)}
      open={fullScreenBackdrop.open}
    >
      {selectComponent()}
    </Backdrop>
  )
}

export default FullScreenBackdrop
