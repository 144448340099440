import { toDownloadApi } from 'api/system'
import errorHandler from 'utils/errorHandler'

export default () => async (dispatch) => {
  try {
    return await toDownloadApi()
  } catch (error) {
    return errorHandler(error)
  }
}
