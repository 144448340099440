import React from 'react'
import { useSelector } from 'react-redux'
import IsLoggedIn from 'components/userInfo/common/sub/isLoggedIn'
import NotLoggedIn from 'components/userInfo/common/sub/notLoggedin'

function UserInfo() {
  const { isLoggedIn } = useSelector(state => state.auth)
  return (
    <>
      {isLoggedIn ? <IsLoggedIn /> : <NotLoggedIn />}
    </>
  )
}

export default UserInfo
