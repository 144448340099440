import React, { useRef, useEffect } from 'react'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import PropTypes from 'prop-types'
import styles from './scrollbar.module.sass'

function Scrollbar({ children }) {
  const myRef = useRef()
  const isMounted = useRef(false)

  useEffect(() => {
    if (children) {
      isMounted.current = true
    }
    if (isMounted.current) {
      myRef.current.getScrollElement().scrollTop = 0
    }
    return () => { isMounted.current = false }
  }, [myRef, children])

  return (
    <SimpleBar
      ref={myRef}
      className={styles.root}
      autoHide={false}
    >
      {children}
    </SimpleBar>
  )
}

Scrollbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
}

Scrollbar.defaultProps = {
  children: [],
}

export default Scrollbar
