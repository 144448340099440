import { getBetInfoAPI } from 'api/externalGame'
import errorHandler from 'utils/errorHandler'

export default payload => async () => {
  try {
    return await getBetInfoAPI({
      memberId: payload.memberId,
      eventId: payload.eventId,
    })
  } catch (error) {
    if (payload.onSearchErrorCallback) {
      payload.onSearchErrorCallback(error)
    }

    return errorHandler(error)
  }
}
