import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoggedIn: false,
  trustDevice: {
    userData: {
      account: null,
      password: null,
      rememberAccount: false,
    },
    // "NORMAL","APPLE","FB","LINE"
    loginType: null,
  },
}
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true
    },
    logout: (state, action) => {
      state.isLoggedIn = false
    },
    setTrustDevice: (state, action) => {
      state.trustDevice.loginType = action.payload.loginType
      state.trustDevice.userData = action.payload.userData
    },
  },
})

export const { login, logout, setTrustDevice } = authSlice.actions
export default authSlice.reducer
