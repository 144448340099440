import newsIcon from 'image/icon/1.svg'
import rookieIcon from 'image/icon/2.svg'
import depositIcon from 'image/icon/3.svg'
import memberIcon from 'image/icon/4.svg'
import rankIcon from 'image/icon/5.svg'
import serviceIcon from 'image/icon/6.svg'

export default [
  {
    label: '最新消息',
    href: '/news',
    name: 'news',
    icon: newsIcon,
  },
  {
    label: '新手專區',
    name: 'rookie',
    icon: rookieIcon,
    subItem: [
      {
        label: '遊戲介紹',
        href: '/gameIntro',
        name: 'gameIntro',
        parent: 'rookie',
      },
      {
        label: '聚寶寶介紹',
        href: '/mascotIntro',
        name: 'mascotIntro',
        parent: 'rookie',
      },
      {
        label: '道具使用說明',
        href: '/propsIntro',
        name: 'propsIntro',
        parent: 'rookie',
      },
      {
        label: '贈禮說明',
        href: '/giftIntro',
        name: 'giftIntro',
        parent: 'rookie',
      },
      {
        label: 'VIP層級介紹',
        href: '/vipIntro',
        name: 'vipIntro',
        parent: 'rookie',
      },
      {
        label: '活躍度說明',
        href: '/activityIntro',
        name: 'activityIntro',
        parent: 'rookie',
      },

    ],
  },
  {
    label: '點數儲值',
    href: '/deposit',
    name: 'deposit',
    icon: depositIcon,
    isSensitive: true,
  },
  {
    label: '會員專區',
    name: 'member',
    icon: memberIcon,
    subItem: [
      {
        label: '我的存摺',
        href: '/passbook',
        name: 'passbook',
        parent: 'member',
      },
      {
        label: '修改密碼',
        href: '/modifyPassword',
        name: 'modifyPassword',
        parent: 'member',
      },
    ],
  },
  {
    label: '活動排行',
    name: 'rank',
    icon: rankIcon,
    href: '/rank',
  },
  {
    label: '客服中心',
    name: 'service',
    icon: serviceIcon,
    subItem: [
      {
        label: '常見問題',
        href: '/question',
        name: 'question',
        parent: 'service',

      },
      {
        label: '停權名單',
        href: '/suspenseList',
        name: 'suspenseList',
        parent: 'service',
      },
      {
        label: '權益說明',
        href: '/rightsDescription',
        name: 'rightsDescription',
        parent: 'service',
      },
      {
        label: '客服資訊',
        href: '/serviceDetail',
        name: 'serviceDetail',
        parent: 'service',
      },
      {
        label: '機率公佈',
        href: '/probabilityDetail',
        name: 'probabilityDetail',
        parent: 'service',
      },
    ],
  },
]
