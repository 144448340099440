import create from 'zustand'

const useStore = create((set, get) => ({
  videoDialog: {
    open: false,
    content: '',
    apiData: {},
    data: {},
    handleOnClose: () => { },
    component: () => null,
    state: null,
  },
  setVideoDialog: videoDialog => set({ videoDialog }),
  closeVideoDialog: () => {
    const { videoDialog } = get()
    if (videoDialog.handleOnClose) {
      videoDialog.handleOnClose()
    }
    set({ videoDialog: { open: false } })
  },
}))
export default useStore
