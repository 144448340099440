import React, {
  useEffect, useRef, useState, useMemo
} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import Dialog from 'components/dialog/common'
import HandleRoutes from 'layouts/officialWebsite/handleRoutes'
import HandleHeader from 'layouts/officialWebsite/desktop/sub/handleHeader'
import AlertDialog from 'components/alertDialog/common'
import ConfirmDialog from 'components/confirmDialog/common'
import ArticleDialog from 'components/articleDialog/common'
import R18AlertDialog from 'components/r18AlertDialog/common'
import InfoDialog from 'components/infoDialog/common'
import VideoDialog from 'components/videoDialog/common'
import Rate18Alert from 'components/rate18Alert/desktop'
import PageDialog from 'components/pageDialog/common'
import FullScreenBackdrop from 'components/fullScreenBackdrop/common'
import theme from 'theme/desktop/muiTheme'
import HandleFooter from 'layouts/officialWebsite/desktop/sub/handleFooter'
import HandleFB from 'layouts/officialWebsite/handleFB'
import { Switch, useLocation } from 'react-router-dom'
import routes from 'router/officialWebsite'
import { getCookie } from 'utils/cookie'
import useStore from 'zustand/r18AlertDialog'
import clsx from 'clsx'
import FireGroup from 'components/fireworkGroup/FireGroup'
import Galaxy from 'views/galaxy/desktop/Galaxy/Galaxy'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import styles from './layout.module.sass'

function Layout() {
  const isMounted = useRef(false)
  const [showHint, setShowHint] = useState(true) // 顯示18禁提示框
  const location = useLocation()
  const { setR18AlertDialog } = useStore()

  const showR18AlertDialog = useMemo(() => {
    setR18AlertDialog({ open: true, component: () => <Rate18Alert setShowHint={setShowHint} /> })
  }, [setR18AlertDialog])

  const checkShowFireGroupPage = extraActivityRouterName.filter(
    cur => location.pathname.startsWith(cur)
  )?.length === 0

  const checkShowGalaxyPage = extraActivityRouterName.filter(
    cur => location.pathname.startsWith(cur)
  )?.length === 0

  const view = useMemo(() => (
    <>
      <HandleHeader />
      <Switch>
        {routes().map((route, i) => (
          <HandleRoutes
            key={route.path}
            {...route}
          />
        ))}
      </Switch>
      {checkShowFireGroupPage && <FireGroup />}
      {checkShowGalaxyPage && <Galaxy starCount={500} />}
      <HandleFooter />
    </>
  ), [checkShowFireGroupPage, checkShowGalaxyPage])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      const checkLevelCookie = getCookie('checkLevel')
      if (checkLevelCookie) {
        setShowHint(false)
        setR18AlertDialog({ open: false })
      }
    }
  }, [setR18AlertDialog])

  useEffect(() => {
    if (showHint) {
      document.querySelector('html').style.overflow = 'hidden'
    } else {
      document.querySelector('html').style.overflow = 'overlay'
    }
  }, [showHint])

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.root}>
        <HandleFB showHint={showHint} />
        {showHint && (
          showR18AlertDialog
        )}
        <div className={clsx(styles.view, showHint && styles.vague)}>
          {view}
        </div>
        <InfoDialog />
        <AlertDialog />
        <ConfirmDialog />
        <ArticleDialog />
        <PageDialog />
        <Dialog />
        <R18AlertDialog />
        <VideoDialog />
        <FullScreenBackdrop />
      </div>
    </ThemeProvider>
  )
}
export default Layout
