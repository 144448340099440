import React from 'react'
import clsx from 'clsx'
import styles from './fireGroup.module.sass'

function FireGroup() {
  return (
    <div className={styles.root}>
      <div className={clsx(styles.firework_base, styles.firework01)} />
      <div className={clsx(styles.firework_base, styles.firework02)} />
      <div className={clsx(styles.firework_base, styles.firework03)} />
    </div>
  )
}

export default FireGroup
