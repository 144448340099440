export const jsonStringify = (str) => {
  try {
    return JSON.stringify(str)
  } catch (err) {
    console.error('jsonStringify::', err)
    return null
  }
}
export const jsonParse = (data) => {
  try {
    if (data) {
      return JSON.parse(data)
    }
    return null
  } catch (err) {
    console.log('jsonParse::', err)
    return null
  }
}
export const getRandom = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min

export const getOpenDate = (year, month, date, hour, minutes, second) => new Date(
  year, month - 1, date, hour, minutes, second
)

export default {
  jsonStringify,
  jsonParse,
  getRandom,
  getOpenDate,
}
