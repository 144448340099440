// eslint-disable-next-line import/no-cycle
import $axios from 'utils/axios'

const auth = {
  loginApi: async (payload) => {
    const { data } = await $axios({
      headers: { 'reToken': payload.reToken },
      method: 'post',
      url: '/auth/loginByNormal',
      data: {
        account: payload.account,
        password: payload.password,
      },
    })
    return data
  },
  loginByAppleApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/auth/loginByApple',
      data: {
        code: payload.code,
        id_token: payload.id_token,
      },
    })
    return data
  },
  loginByFbApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/auth/loginByFB',
      data: {
        accessToken: payload.accessToken,
      },
    })
    return data
  },
  loginByLineApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/auth/loginByLine',
      data: payload,
    })
    return data
  },
  renewApi: async (payload) => {
    const data = await $axios({
      method: 'post',
      url: '/auth/renew',
    })
    return data
  },
  logoutApi: async (payload) => {
    await $axios({
      method: 'post',
      url: '/auth/logout',
    })
  },
  bindPhoneApi: async (payload) => {
    const { data } = await $axios({
      headers: { 'reToken': payload.reToken },
      method: 'post',
      url: '/auth/bindCellPhone',
      data: {
        cellPhoneNum: payload.phone,
      },
    })
    return data
  },
  verifyPhoneApi: async (payload) => {
    await $axios({
      headers: { 'reToken': payload.reToken },
      method: 'post',
      url: '/auth/verifyBindCellPhone',
      data: {
        cellPhoneBindRequestId: payload.cellPhoneBindRequestId,
        verificationCode: payload.verificationCode,
      },
    })
  },
  verifyTrustDeviceApi: async (payload) => {
    await $axios({
      method: 'post',
      url: '/auth/verifyTrustDeviceBind',
      data: {
        action: payload.action,
        trustDeviceBindRequestId: payload.trustDeviceBindRequestId,
        verificationCode: payload.verificationCode,
      },
    })
  },
  forgetPasswordApi: async (payload) => {
    const { data } = await $axios({
      headers: { 'reToken': payload.reToken },
      method: 'post',
      url: '/auth/forgetPassword',
      data: {
        account: payload.account,
        cellPhoneNum: payload.phone,
      },
    })
    return data
  },
  changePasswordApi: async (payload) => {
    await $axios({
      method: 'post',
      url: '/auth/changePassword',
      data: {
        action: payload.action,
        account: payload.account,
        changePasswordRequestId: payload.changePasswordRequestId,
        newPassword: payload.newPassword,
        verificationCode: payload.verificationCode,
      },
    })
  },
}
export const {
  loginApi,
  renewApi,
  logoutApi,
  loginByFbApi,
  loginByLineApi,
  bindPhoneApi,
  verifyPhoneApi,
  forgetPasswordApi,
  verifyTrustDeviceApi,
  loginByAppleApi,
  changePasswordApi,
} = auth
export default auth
