import React from 'react'
import { Dialog as MuiDialog } from '@material-ui/core'
import styles from 'components/videoDialog/common/videoDialog.module.sass'
import useStore from 'zustand/videoDialog'

function VideoDialog() {
  const dialog = useStore(state => state.videoDialog)
  const { setVideoDialog } = useStore()

  function handleOnClose() {
    if (dialog.handleOnClose) {
      dialog.handleOnClose()
    }
    setVideoDialog({ open: false })
  }
  function selectComponent() {
    if (!dialog.component) {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.text}>
              {dialog.content}
            </div>
          </div>
        </>
      )
    }
    return <dialog.component />
  }

  return (
    <MuiDialog
      maxWidth={false}
      open={dialog.open}
      onClose={() => { handleOnClose() }}
      classes={{
        paperWidthFalse: styles.paperWidthFalse,
      }}
    >
      <div className={styles.root}>
        {selectComponent()}
      </div>
    </MuiDialog>
  )
}

export default VideoDialog
