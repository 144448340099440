import { verifyTrustDeviceApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import dialogUseStore from 'zustand/dialog'
import alertDialogUseStore from 'zustand/alertDialog'

export default data => async (dispatch) => {
  try {
    await verifyTrustDeviceApi(data)
  } catch (error) {
    // 驗證碼請求次數過多需關掉視窗
    if (error.retStatus.StatusCode === 10346) {
      unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog({
          ...alertDialogUseStore.getState().alertDialog,
          handleOnClose: async () => { await dialogUseStore.getState().setDialog({ open: false }) },
        })
      })
    }

    errorHandler(error)
  }
}
