/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import handleTdFelid from 'components/table/common/handleTdFelid'
import styles from './table.module.sass'

function Table({
  headerList, bodyList, type, data,
}) {
  return (
    <div>
      {bodyList && bodyList.map((item, index) => (
        <div
          data-name={index}
          key={index}
          className={styles.listContainer}
        >
          {headerList.map(headerItem => (
            <div
              className={clsx(type === 'rank' && [
                item.rank === 1 && styles.rankGoldBorder,
                item.rank === 2 && styles.rankSilverBorder,
                item.rank === 3 && styles.rankBronzeBorder], styles.list)}
              key={headerItem.th}
            >
              <div
                className={styles.label}
                data-name={headerItem.th}
                key={headerItem.th}
              >
                {headerItem.th}
              </div>
              <div
                className={clsx(styles.content, clsx(type === 'rowspan' && styles.rowspan))}
              >
                {handleTdFelid(item, headerItem, data)}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
Table.propTypes = {
  headerList: PropTypes.array,
  bodyList: PropTypes.array,
  type: PropTypes.string,
  data: PropTypes.object,
}

Table.defaultProps = {
  headerList: [],
  bodyList: [],
  type: null,
  data: {},
}
export default Table
