import React, {
  useState, useEffect, useRef, useCallback
} from 'react'
import DOMPurify from 'dompurify'
import Slide from '@material-ui/core/Slide'
import LoadingBackdrop from 'components/loadingBackdrop/common'
import { Dialog as MuiDialog } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { modifyPageDialog } from 'redux/system/slice'
import getPageService from 'services/article/getPage'
import Scrollbar from 'components/scrollbar/common'
import styles from './pageDialog.module.sass'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function PageDialog() {
  const dispatch = useDispatch()
  const [content, setContent] = useState('')
  const { pageDialog } = useSelector(state => state.system)
  const [isLoading, setIsLoading] = useState(false)
  const isMounted = useRef(false)

  const closeDialog = useCallback(() => { dispatch(modifyPageDialog({ open: false })) }, [dispatch])

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const data = await dispatch(getPageService(pageDialog.apiName))
        if (data.externalURL && data.externalURL !== '') {
          window.open(data.externalURL)
          closeDialog()
        } else {
          setContent(DOMPurify.sanitize(data.content, { ADD_TAGS: ['iframe'], ADD_ATTR: ['allow'] }))
        }
      } catch (error) {
        //
      }
    }
    isMounted.current = true
    if (pageDialog.open) {
      fetchContent()
      setIsLoading(false)
    }
    return () => { isMounted.current = false }
  }, [closeDialog, dispatch, pageDialog.apiName, pageDialog.open])

  return (
    <MuiDialog
      fullWidth
      scroll="paper"
      TransitionComponent={Transition}
      open={pageDialog.open}
      onClose={closeDialog}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        <div className={styles.title}>{pageDialog.title}</div>
        <div className={styles.content}>
          <div className={styles.scrollbarFrame}>
            <Scrollbar>
              <div className={styles.scrollbarContent}>
                <div
                  className={styles.innerHtml}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </div>
            </Scrollbar>
          </div>
          <LoadingBackdrop isLoading={isLoading} />
        </div>
        <div className={styles.functionFooter}>
          <div
            type="button"
            aria-hidden="true"
            className={styles.closeBtn}
            onClick={closeDialog}
          >
            <div className={styles.closeText}>關閉</div>
            <div className={styles.closeIcon} />
          </div>
        </div>

      </div>
    </MuiDialog>
  )
}

export default PageDialog
