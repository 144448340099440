import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import variables from './variables.module.sass'

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: variables.primaryColor,
    },
    secondary: {
      main: variables.secondaryColor,
    },
    error: {
      main: variables.errorColor,
    },
  },
  typography: {
    fontFamily: variables.fontFamily,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: variables.backdropBackground,
      },
    },
    MuiDialog: {
      root: {
        zIndex: '1500 !important',
      },
    },
    MuiTooltip: {
      popper: {
        zIndex: '1500 !important',
      },
    },
  },
})
export default responsiveFontSizes(MuiTheme)
