import React from 'react'
import Header from 'views/header/desktop'
import HeaderSubPage from 'views/header/desktop/HeaderSubPage'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import { useLocation } from 'react-router-dom'

function HandleHeader() {
  const location = useLocation()
  const checkShowSubPage = extraActivityRouterName.filter(
    cur => location.pathname.startsWith(cur)
  )?.length === 0

  return (
    <>
      {location.pathname === '/' && <Header />}
      {location.pathname !== '/' && checkShowSubPage && <HeaderSubPage />}
    </>
  )
}
export default HandleHeader
