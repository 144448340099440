import React from 'react'
import Footer from 'views/footer/desktop'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import { useLocation } from 'react-router-dom'

function HandleFooter() {
  const location = useLocation()
  const notExtraActivityRouterName = extraActivityRouterName
    .filter(cur => location.pathname.startsWith(cur))?.length === 0
  return (
    <>
      {notExtraActivityRouterName && <Footer />}
    </>
  )
}
export default HandleFooter
