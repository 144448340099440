import create from 'zustand'

const useStore = create((set, get) => ({
  r18AlertDialog: {
    open: false,
    title: '',
    content: '',
    apiData: {},
    data: {},
    handleOnClose: () => { },
    component: () => null,
    state: null,
  },
  setR18AlertDialog: r18AlertDialog => set({ r18AlertDialog }),
  closeR18AlertDialog: () => {
    const { r18AlertDialog } = get()
    if (r18AlertDialog.handleOnClose) {
      r18AlertDialog.handleOnClose()
    }
    set({ r18AlertDialog: { open: false } })
  },
}))
export default useStore
