import React from 'react'
import BottomNavigation from 'views/bottomNavigation/mobile'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import { useLocation } from 'react-router-dom'

function HandleNavigation() {
  const location = useLocation()
  const notExtraActivityRouterName = extraActivityRouterName
    .filter(cur => location.pathname.startsWith(cur))?.length === 0
  return (
    <>
      {notExtraActivityRouterName && <BottomNavigation />}
    </>
  )
}
export default HandleNavigation
