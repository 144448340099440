import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  suspenseList: [],

}
const suspenseListSlice = createSlice({
  name: 'suspenseList',
  initialState,
  reducers: {
    setSuspenseList: (state, action) => {
      state.suspenseList = action.payload.suspenseList
    },
  },
})

export const {
  setSuspenseList,
} = suspenseListSlice.actions
export default suspenseListSlice.reducer
