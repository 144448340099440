import scriptjs from 'scriptjs'

export default async () => new Promise((resolve, reject) => {
  const urlhost = window.location.host
  const state = Math.floor(Date.now() / 1000).toString()
  scriptjs.get('https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js', async () => {
    const params = {
      clientId: 'com.jixi.ios.gbao888.siwa',
      redirectURI: `https://${urlhost}/auth/loginByApple`,
      usePopup: true, // or false defaults to false
      scope: 'name email',
      state,
    }
    try {
      await window.AppleID.auth.init(params)
      const result = await window.AppleID.auth.signIn()
      if (result.authorization) {
        resolve({ originalState: state, ...result.authorization })
      }
    } catch (err) {
      console.log(err)
      reject(new Error('APPLE_ERROR'))
    }
  })
})
