import { getRandom } from 'utils/common'

class Star {
  constructor(p5, canvasWidth, canvasHeight, color, starSize) {
    this.x = getRandom(0, canvasWidth)
    this.y = getRandom(0, canvasHeight)
    this.size = getRandom(starSize.min, starSize.max)
    this.time = getRandom(1, 15000)
    this.p5 = p5
    this.color = color
  }

  draw(p5) {
    this.time += 0.045
    const scale = this.size + Math.sin(this.time) * 2
    this.p5.noStroke()
    this.p5.fill(this.color.r, this.color.g, this.color.b, this.color.a)
    this.p5.ellipse(this.x, this.y, scale, scale)
  }
}

export default Star
