import React from 'react'
import Header from 'views/header/mobile'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import { useLocation } from 'react-router-dom'

function HandleHeader() {
  const location = useLocation()
  const notExtraActivityRouterName = extraActivityRouterName
    .filter(cur => location.pathname.startsWith(cur))?.length === 0
  return (
    <>
      {(notExtraActivityRouterName) && <Header />}
    </>
  )
}
export default HandleHeader
