import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import useStore from 'zustand/alertDialog'
import styles from './alertDialog.module.sass'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)
function AlertDialog() {
  const alertDialog = useStore(state => state.alertDialog)

  const { setAlertDialog } = useStore()
  async function handleOnClose() {
    if (alertDialog.handleOnClose) {
      alertDialog.handleOnClose()
    }
    setAlertDialog({ open: false })
  }
  function selectComponent() {
    if (!alertDialog.component) {
      return (
        <>
          <div className={styles.text}>
            {alertDialog.content}
          </div>
        </>
      )
    }
    return alertDialog.component
  }
  return (
    <Dialog
      TransitionComponent={Transition}
      classes={{
        paper: styles.paper,
      }}
      open={alertDialog.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => {
        handleOnClose()
        if (alertDialog.onConfirm) {
          alertDialog.onConfirm()
        }
      }}
    >
      <div className={styles.root}>
        <div className={styles.title}>{alertDialog.title}</div>
        {selectComponent()}
        <button
          className={styles.btn}
          type="button"
          onClick={() => {
            handleOnClose()
            if (alertDialog.onConfirm) {
              alertDialog.onConfirm()
            }
          }}
        >
          {alertDialog.source === 'serialNumber'
            ? '確認' : '我知道了'}

        </button>
      </div>
    </Dialog>
  )
}
export default AlertDialog
