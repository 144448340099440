import {
  AppBar, Toolbar
} from '@material-ui/core'
import React, { useState } from 'react'
import Drawer from 'views/header/mobile/sub/drawer'
import UserInfo from 'components/userInfo/common'
import Logo from 'components/logo/common'
import menu from 'image/btn/menu.png'
import close from 'image/btn/Button_Close.png'
import styles from './header.module.sass'

function Header() {
  const [openDrawer, setOpenedDrawer] = useState(false)
  const toggleDrawer = open => (event) => {
    setOpenedDrawer(open)
  }
  const MenuBtn = (data) => {
    if (!openDrawer) {
      return (
        <img
          className={styles.menuBtn}
          src={menu}
          alt="menu"
          type="button"
          aria-hidden="true"
          onClick={toggleDrawer(true)}
        />
      )
    }
    return (
      <img
        className={styles.menuBtn}
        src={close}
        alt="menu"
        type="button"
        aria-hidden="true"
        onClick={toggleDrawer(false)}
      />
    )
  }

  return (
    <>
      <AppBar>
        <Toolbar className={styles.toolbar}>
          <div className={styles.logoBlock}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </div>
          <UserInfo />
          <MenuBtn />
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="right"
        openDrawer={openDrawer}
        setOpenedDrawer={setOpenedDrawer}
      />

    </>

  )
}

export default Header
