import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  serverGameList: [],
  activityList: [],
  richTag: [],
  dragonTag: [],
  tigerTag: [],
  phoenixTag: [],
  itemTag: [],
  topicDragonTag: [],
  topicTigerTag: [],
  topicPhoenixTag: [],
}
const rankSlice = createSlice({
  name: 'rank',
  initialState,
  reducers: {
    setServerGameList: (state, action) => {
      state.serverGameList = action.payload.serverGameList
    },
    setActivityList: (state, action) => {
      state.activityList = action.payload.activityList
    },
    setRichTag: (state, action) => {
      state.richTag = action.payload.richTag
    },
    setDragonTag: (state, action) => {
      state.dragonTag = action.payload.dragonTag
    },
    setTigerTag: (state, action) => {
      state.tigerTag = action.payload.tigerTag
    },
    setPhoenixTag: (state, action) => {
      state.phoenixTag = action.payload.phoenixTag
    },
    setItemTag: (state, action) => {
      state.itemTag = action.payload.itemTag
    },
    setTopicDragonTag: (state, action) => {
      state.topicDragonTag = action.payload.topicDragonTag
    },
    setTopicTigerTag: (state, action) => {
      state.topicTigerTag = action.payload.topicTigerTag
    },
    setTopicPhoenixTag: (state, action) => {
      state.topicPhoenixTag = action.payload.topicPhoenixTag
    },
  },
})

export const {
  setServerGameList,
  setActivityList,
  setRichTag,
  setDragonTag,
  setTigerTag,
  setPhoenixTag,
  setItemTag,
  setTopicDragonTag,
  setTopicTigerTag,
  setTopicPhoenixTag,
} = rankSlice.actions
export default rankSlice.reducer
