import React from 'react'
import styles from './errorMessageBlock.module.sass'

function ErrorMessageBlock() {
  return (
    <div className={styles.root}>
      <p>查無此活動</p>
    </div>
  )
}

export default ErrorMessageBlock
