import React, { useState } from 'react'
import styles from 'components/dialog/common/dialog.module.sass'
import Step1 from './sub/step1'
import Step2 from './sub/step2'
import Success from './sub/success'

function ForgetPassword() {
  const [type, setType] = useState('STEP1')
  const [apiData, setApiData] = useState({})

  function handleContent() {
    switch (type) {
      case 'STEP1':
        return <Step1 setType={setType} setApiData={setApiData} />
      case 'STEP2':
        return <Step2 setType={setType} apiData={apiData} />
      case 'SUCCESS':
        return <Success />
      default:
        return <Step1 />
    }
  }
  return (
    <div className={styles.root}>
      {handleContent()}
    </div>
  )
}

export default ForgetPassword
