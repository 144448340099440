import React from 'react'

const component = {
  activity1: React.lazy(() => import('views/activity/activities/activity1/desktop')),
  activity2: React.lazy(() => import('views/activity/activities/activity2/desktop')),
  activity3: React.lazy(() => import('views/activity/activities/activity3/desktop')),
  activity4: React.lazy(() => import('views/activity/activities/activity4/desktop')),
  activity5: React.lazy(() => import('views/activity/activities/activity5/desktop')),
  activity6: React.lazy(() => import('views/activity/activities/activity6/desktop')),
  activity7: React.lazy(() => import('views/activity/activities/activity7/desktop')),
  activity8: React.lazy(() => import('views/activity/activities/activity8/desktop')),
  activity9: React.lazy(() => import('views/activity/activities/activity9/desktop')),
  activity10: React.lazy(() => import('views/activity/activities/activity10/desktop')),
}

export default component
