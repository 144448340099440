// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import useFullScreenBackdropStore from 'zustand/fullScreenBackdrop'

export default async () => new Promise((resolve) => {
  const state = Math.floor(Date.now() / 1000).toString()
  localStorage.setItem('lineState', JSON.stringify(state))

  const responseType = 'code'
  const clientId = '1655874810'
  const redirectUri = handleRedirectURI()

  const scope = 'profile'
  const uiLocales = 'zh-TW'
  const link = `https://access.line.me/oauth2/v2.1/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}&ui_locales=${uiLocales}`
  unstable_batchedUpdates(() => {
    useFullScreenBackdropStore.getState().hideFullScreenBackdrop()
  })
  window.location.href = link
})

function handleRedirectURI() {
  const urlHost = window.location.host
  const urlPathName = window.location.pathname
  let redirectUri = ''
  switch (urlPathName) {
    case '/deregisterAccount': // 註銷帳號中使用 line 登入
      redirectUri = `https://${urlHost}${urlPathName}/?loginByLine`
      break
    default:
      redirectUri = `https://${urlHost}/?loginByLine`
  }
  return redirectUri
}
