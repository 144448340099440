/* eslint-disable camelcase */
import { unstable_batchedUpdates } from 'react-dom'
import useStore from 'zustand/dialog'
import VerifyPhone from 'views/login/common/verifyPhone'

export default async (memberInfoData, dispatch) => {
  if (!memberInfoData.cellPhoneBind) {
    await unstable_batchedUpdates(() => {
      useStore.getState().setDialog({
        open: true,
        component: VerifyPhone,
      })
    })
  } else {
    await unstable_batchedUpdates(() => {
      useStore.getState().setDialog({
        open: false,
      })
    })
  }
}
