/* eslint-disable camelcase */
import { unstable_batchedUpdates } from 'react-dom'
import useStore from 'zustand/dialog'
import TrustDevice from 'views/login/common/trustDevice'

export default async (result) => {
  await unstable_batchedUpdates(() => {
    useStore.getState().setDialog({
      type: 'TRUST_DEVICE',
      open: true,
      component: TrustDevice,
      apiData: result && result.bindTrustDeviceRes,
    })
  })
}
