import React, { useRef } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { useLocation } from 'react-router-dom'
import extraActivityRouterName from 'constants/extraActivityRouterName'
import styles from './handleToTop.module.sass'

function HandleToTop() {
  const toTopRef = useRef()
  const location = useLocation()
  const notExtraActivityRouterName = extraActivityRouterName
    .filter(cur => location.pathname.startsWith(cur))?.length === 0

  const scrollToTop = () => {
    if (window.scrollTo) {
      window.scrollTo({ 'behavior': 'smooth', 'top': 0 })
    }
  }

  useScrollPosition(({ prevPos, currPos }) => {
    if (toTopRef.current) {
      toTopRef.current.style.top = `${window.innerHeight * 0.6 - currPos.y}px`
      toTopRef.current.style.transition = 'top 0.3s ease-out'
    }
  })

  return (
    <>
      {notExtraActivityRouterName && (
        <div
          className={styles.root}
          ref={toTopRef}
          aria-hidden="true"
          type="button"
          onClick={scrollToTop}
        />
      )}
    </>
  )
}
export default HandleToTop
