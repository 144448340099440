import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import toDownload from 'services/system/toDownload'
import customerServicesIcon from 'image/icon/7.svg'
import depositIcon from 'image/icon/8.svg'
import downloadIcon from 'image/icon/9.svg'
import styles from './bottomNavigation.module.sass'

function BottomNavigation() {
  const [downloadUrl, setDownloadUrl] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    const getDownloadUrl = async () => {
      const rdURL = await dispatch(toDownload())
      setDownloadUrl(rdURL)
    }
    getDownloadUrl()
  }, [dispatch])

  const isTurnOffDeposit = (process.env.REACT_APP_TURN_OFF_DEPOSIT === 'true')

  return (
    <div className={styles.root}>
      <Link to="/serviceDetail">
        <div className={styles.button}>
          <img className={styles.customerService} src={customerServicesIcon} alt="customerServicesIcon" />
          <div className={styles.title}>呼叫客服</div>
        </div>
      </Link>
      {!isTurnOffDeposit && (
        <Link to="/deposit">
          <div className={styles.button}>
            <img className={styles.deposit} src={depositIcon} alt="depositIcon" />
            <div className={styles.title}>立即儲值</div>
          </div>
        </Link>
      )}
      <a
        href={downloadUrl}
        target="_blank"
        className={styles.downloadUrl}
        rel="noreferrer"
      >
        <div className={styles.button}>
          <img className={styles.download} src={downloadIcon} alt="downloadIcon" />
          <div className={styles.title}>立即下載</div>
        </div>
      </a>
    </div>
  )
}

export default BottomNavigation
