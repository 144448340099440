/* eslint-disable react/no-unknown-property */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './fbMsg.module.sass'

function FbMsg({ showHint }) {
  return (
    process.env.NODE_ENV !== 'development'
    && (
      <div className={styles.root}>
        <div id="fb-root" className={clsx(showHint && styles.vague)} />
        <div
          id="fb-customer-chat"
          className={clsx('fb-customerchat', styles.csChat)}
          page_id="106869691698969"
          attribution="biz_inbox"
          greeting_dialog_display="hide"
        />
      </div>
    )
  )
}

FbMsg.propTypes = {
  showHint: PropTypes.bool,

}

FbMsg.defaultProps = {
  showHint: null,
}

export default FbMsg
