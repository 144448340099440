import React from 'react'
import styles from 'components/infoDialog/common/infoDialog.module.sass'
import PropTypes from 'prop-types'
import CloseBtn from 'image/Button_Close.png'
import useStore from 'zustand/infoDialog'

function Title({ title }) {
  const infoDialog = useStore(state => state.infoDialog)
  const { setInfoDialog } = useStore()

  function handleOnClose() {
    if (infoDialog.handleOnClose) {
      infoDialog.handleOnClose()
    }
    setInfoDialog({ open: false })
  }
  return (
    <>
      <div className={styles.title}>
        {title}
        <img
          aria-hidden="true"
          type="button"
          onClick={() => { handleOnClose() }}
          className={styles.closeBtn}
          src={CloseBtn}
          alt="CloseBtn"
        />
      </div>

    </>
  )
}

Title.propTypes = {
  title: PropTypes.string,
}

Title.defaultProps = {
  title: '',
}

export default Title
