/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import EighteenPlus from 'image/Rate18+.png'
import useStore from 'zustand/r18AlertDialog'
import styles from './rate18Alert.module.sass'

function Rate18Alert({ setShowHint }) {
  const [notRemindAgain, setNotRemindAgain] = useState(false) // 是否勾選不再顯示
  const isR18 = useRef(false) // 是否已滿18歲
  const { setR18AlertDialog } = useStore()

  const handleFull18 = useCallback(() => {
    if (!isR18.current) {
      setShowHint(false)
      setR18AlertDialog({ open: false })
      if (notRemindAgain) {
        window.document.cookie = 'checkLevel=true'
      }
    }
  }, [notRemindAgain, setR18AlertDialog, setShowHint])

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.title}>
          聚寶Online 提醒您
        </div>
        <div className={styles.content}>
          <div>
            <img src={EighteenPlus} alt="限制級 18+" />
          </div>
          <div className={styles.text}>
            <p>
              提醒：本網站服務於遊戲軟體分級為限制級，
            </p>
            <p>
              使用本服務前請確認您已滿18歲。
            </p>
          </div>
          <div className={styles.btn}>
            <a href="https://www.google.com.tw" className={styles.notFullBtn}>
              未滿18歲
            </a>
            <button className={styles.fullBtn} type="button" onClick={handleFull18}>
              已滿18歲
            </button>
          </div>
          <div className={styles.contentBottom} />
        </div>
        <div className={styles.footer}>
          <div className={styles.hint}>
            <input
              id="notRemindAgain"
              type="checkbox"
              onChange={(e) => { setNotRemindAgain(e.target.checked) }}
            />
            <label htmlFor="notRemindAgain">不再提示</label>
          </div>
        </div>
      </div>
    </div>
  )
}
Rate18Alert.propTypes = {
  setShowHint: PropTypes.func,
}

Rate18Alert.defaultProps = {
  setShowHint: null,
}

export default Rate18Alert
