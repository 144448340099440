export const REBATE_TYPE = {
  EXTERNAL: 1,
  INTERNAL: 2,
}

export const ACCUMULATION_TYPE = {
  AccumulatedBet: {
    id: 'totalBet',
    label: '累積押注',
  },
  AccumulatedWinningPoints: {
    id: 'totalWin',
    label: '累積贏分',
  },
}

export default {
  REBATE_TYPE,
}
