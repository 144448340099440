import {
  useCallback, useEffect, useRef, useState
} from 'react'

// ** import third party
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { formatISO9075 } from 'date-fns'
import { map } from 'ramda'

// ** import config
import headerList from 'constants/externalGame/headList'
import { ACCUMULATION_TYPE, REBATE_TYPE } from 'constants/externalGame/rebateConfig'

// ** import apis
import getBetInfoAPI from 'services/externalGame/getBetInfo'
import getGameListAPI from 'services/externalGame/getGameList'

const SEARCH_MAX_LENGTH = 12 // 搜尋框文字最大長度

export default function usePage() {
  const dispatch = useDispatch()
  const query = new URLSearchParams(useLocation().search)
  const eventId = query.get('eventId')

  if (eventId === null || eventId === undefined || eventId === '') {
    window.location.href = '/'
  }

  const { memberId: globalScopeMemberId } = useSelector(state => state.member)

  const [eventSearchStatus, setEventSearchStatus] = useState({
    done: false,
    type: REBATE_TYPE.EXTERNAL,
    displayName: null,
    gameDisplayName: null,
    startTime: '-',
    endTime: '-',
    accumulationUnit: ACCUMULATION_TYPE.AccumulatedBet.label,
    table: [],
    tableHeader: headerList,
  })
  const isMounted = useRef(false)
  const [memberId, setMemberId] = useState(globalScopeMemberId || '')
  const [errorData, setErrorData] = useState({
    isError: false,
    msg: '',
  })
  const [result, setResult] = useState({
    accumulatedBet: '-', // 累積押注
    percentage: '-', // 獎勵回饋百分比
    earnedCoins: '-', // 可獲得金幣量
  })

  const fetchGameList = useCallback(async () => {
    try {
      const {
        type,
        title,
        displayName,
        startTime,
        endTime,
        accumulationType,
        table,
      } = await dispatch(getGameListAPI({ eventId }))

      const accumulationUnit = () => {
        const typeMap = {
          TotalBet: ACCUMULATION_TYPE.AccumulatedBet.label,
          TotalWin: ACCUMULATION_TYPE.AccumulatedWinningPoints.label,
        }
        return typeMap[accumulationType] || ACCUMULATION_TYPE.AccumulatedBet.label
      }

      setEventSearchStatus({
        done: true,
        type,
        displayName,
        gameDisplayName: title,
        startTime: formatISO9075(startTime),
        endTime: formatISO9075(endTime),
        accumulationUnit: accumulationUnit(accumulationType),
        table: map(list => ({
          rewardThreshold: list[0],
          accumulatedType: list[1],
          percentage: list[2],
        }), table),
        tableHeader: [
          { th: '獎勵門檻', apiTdName: 'rewardThreshold' },
          { th: accumulationUnit(accumulationType), apiTdName: 'accumulatedType' },
          { th: '獎勵回饋百分比', apiTdName: 'percentage' },
        ],
      })
    } catch (error) {
      setEventSearchStatus({
        done: true,
        gameDisplayName: null,
      })
    }
  }, [dispatch, eventId])

  const handleSearchErrorCallback = useCallback(
    (err) => {
      const { retStatus: { StatusMsg } } = err

      setErrorData({
        isError: true,
        msg: StatusMsg,
      })
    }, []
  )

  const handleSearchButtonClick = useCallback(
    async () => {
      if (memberId.trim() === '') {
        setErrorData({
          isError: true,
          msg: '會員 ID 不得為空',
        })
        return
      }

      setErrorData({
        isError: false,
        msg: '',
      })

      try {
        const response = await dispatch(
          getBetInfoAPI({
            memberId,
            eventId: Number(eventId),
            onSearchErrorCallback: handleSearchErrorCallback,
          })
        )

        setResult({
          accumulatedBet: (response.accumulatedBet / 100).toLocaleString(),
          percentage: response.percentage !== null ? `${response.percentage / 100}%` : '未達門檻',
          earnedCoins: response.earnedCoins !== null ? (response.earnedCoins / 100).toLocaleString() : '-',
        })
      } catch (error) {
        console.error(error)
      }
    }, [dispatch, eventId, memberId, handleSearchErrorCallback]
  )

  const handleMemberIdChange = useCallback((e) => {
    if (e.target.value.length > SEARCH_MAX_LENGTH) {
      return
    }

    setMemberId(e.target.value)
  }, [])

  useEffect(() => {
    setMemberId(globalScopeMemberId)
  }, [globalScopeMemberId])

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      fetchGameList()
    } else {
      isMounted.current = true
    }
  }, [fetchGameList])

  return [
    memberId,
    result,
    errorData,
    eventSearchStatus,
    handleSearchButtonClick,
    handleMemberIdChange,
  ]
}
