/* eslint-disable camelcase */
import appleSignIn from 'utils/auth/appleSignIn'
import loginByAppleService from 'services/auth/loginByApple'
import { unstable_batchedUpdates } from 'react-dom'
import alertDialogUseStore from 'zustand/alertDialog'
import useFullScreenBackdropStore from 'zustand/fullScreenBackdrop'

export default async (dispatch) => {
  try {
    const data = await appleSignIn()
    if (data.originalState !== data.state) {
      const detail = {
        open: true,
        title: 'APPLE 登入錯誤',
        content: 'APPLE 登入錯誤，請重新登入',
      }
      await unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog(detail)
      })
    } else {
      unstable_batchedUpdates(() => {
        useFullScreenBackdropStore.getState().hideFullScreenBackdrop()
      })
      await dispatch(loginByAppleService({ code: data.code, id_token: data.id_token }))
    }
  } catch (error) {
    unstable_batchedUpdates(() => {
      useFullScreenBackdropStore.getState().hideFullScreenBackdrop()
    })
    if (error.message === 'APPLE_ERROR') {
      unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog({
          open: true,
          title: '',
          content: 'APPLE 登入錯誤',
        })
      })
    }
    //
  }
}
