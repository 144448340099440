import React from 'react'

const component = {
  home: React.lazy(() => import('views/home/desktop')),
  news: React.lazy(() => import('views/news/desktop')),
  gameIntro: React.lazy(() => import('views/gameIntro/desktop')),
  vipIntro: React.lazy(() => import('views/vipIntro/desktop')),
  giftIntro: React.lazy(() => import('views/giftIntro/desktop')),
  mascotIntro: React.lazy(() => import('views/mascotIntro/desktop')),
  propsIntro: React.lazy(() => import('views/propsIntro/desktop')),
  activityIntro: React.lazy(() => import('views/activityIntro/desktop')),
  deposit: React.lazy(() => import('views/deposit/desktop')),
  passbook: React.lazy(() => import('views/passbook/desktop')),
  modifyPassword: React.lazy(() => import('views/modifyPassword/desktop')),
  rank: React.lazy(() => import('views/rank/desktop')),
  question: React.lazy(() => import('views/question/desktop')),
  rightsDescription: React.lazy(() => import('views/rightsDescription/desktop')),
  serviceDetail: React.lazy(() => import('views/serviceDetail/desktop')),
  suspenseList: React.lazy(() => import('views/suspenseList/desktop')),
  article: React.lazy(() => import('views/article/desktop')),
  notFound: React.lazy(() => import('views/notFound/desktop')),
  fbCustomer: React.lazy(() => import('views/fbCustomer/desktop')),
  intro: React.lazy(() => import('views/intro/desktop')),
  probabilityDetail: React.lazy(() => import('views/probabilityDetail/desktop')),
  activity: React.lazy(() => import('views/activity/desktop')),
  activityPage: React.lazy(() => import('views/activityPage')),
  deregisterAccount: React.lazy(() => import('views/deregisterAccount/desktop')),
  externalGamesRebate: React.lazy(() => import('views/externalGamesRebate/desktop')),
}

export default component
