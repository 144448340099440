import React, { useState, useEffect, useCallback } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import IsMobile from 'utils/isMobile'
import ScrollToTop from 'utils/scrollToTop'
import MobileLayout from 'layouts/officialWebsite/mobile'
import AppLayout from 'layouts/appView'
import DesktopLayout from 'layouts/officialWebsite/desktop'
import { StylesProvider } from '@material-ui/core/styles'
import 'theme/common/theme.module.sass'
import history from 'utils/history'
import useLineLoginCallback from 'utils/auth/lineLoginCallback'
import initReCaptchaV2Invisible from 'utils/reCaptchaV2'
import { useLocation } from 'react-router-dom'
import getServerTimeApi from 'services/service/getServerTime'
import { useDispatch } from 'react-redux'
import useSystemDataStore from 'zustand/systemData'

function App() {
  const dispatch = useDispatch()
  const location = useLocation()
  const [startRender, setStartedRender] = useState(false)
  const setServerTime = useSystemDataStore(state => state.setServerTime)
  useLineLoginCallback()

  const getServerTime = useCallback(
    async () => {
      const serverTime = await dispatch(getServerTimeApi())
      setServerTime(serverTime)
    },
    [dispatch, setServerTime]
  )

  useEffect(() => {
    getServerTime()
  }, [getServerTime])

  useEffect(() => {
    initReCaptchaV2Invisible()
    setStartedRender(true)
    history.location.state = null
  }, [startRender])
  function handleLayout() {
    // app view
    if (location.pathname.split('/')[1] === 'appView') {
      return <AppLayout />
    }
    // official website
    const mobileLayout = IsMobile()
    if (!startRender) {
      return null
    }
    return mobileLayout ? <MobileLayout /> : <DesktopLayout />
  }
  return (
    <StylesProvider injectFirst>
      <ScrollToTop />
      <CssBaseline />
      {handleLayout()}
    </StylesProvider>
  )
}

export default App
