import React from 'react'
import youtubeIcon from 'image/icon/youtube.svg'
import facebookIcon from 'image/icon/FB.svg'
import lineIcon from 'image/icon/line.svg'
import PropTypes from 'prop-types'
import styles from './relatedLinks.module.sass'

function RelatedLinks({ customizedStyles }) {
  return (
    <div className={styles.root}>
      <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noreferrer">
        <div className={styles.youtube} style={customizedStyles} title="我們的 Youtube 頻道">
          <img src={youtubeIcon} alt="Youtube" />
        </div>
      </a>
      <a href={process.env.REACT_APP_FB_LINK} target="_blank" rel="noreferrer">
        <div className={styles.facebook} style={customizedStyles} title="我們的 Facebook 粉絲團">
          <img src={facebookIcon} alt="Facebook" />
        </div>
      </a>
      <a href={process.env.REACT_APP_LINE_LINK} target="_blank" rel="noreferrer">
        <div className={styles.line} style={customizedStyles} title="我們的 Line 官方帳號">
          <img src={lineIcon} alt="Line" />
        </div>
      </a>
    </div>
  )
}

RelatedLinks.propTypes = {
  customizedStyles: PropTypes.object,
}

RelatedLinks.defaultProps = {
  customizedStyles: {},
}

export default RelatedLinks
