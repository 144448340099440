import axios from 'axios'
import alertDialogUseStore from 'zustand/alertDialog'
import dialogUseStore from 'zustand/dialog'
import confirmDialogUseStore from 'zustand/confirmDialog'
// eslint-disable-next-line import/no-cycle
import logoutService from 'services/auth/logout'

import errorCodeMappingMsg from 'constants/errorCodeMappingMsg'
import store from 'redux/store'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import needReloadStatusCodeList from 'constants/needReloadStatusCodeList'
import history from 'utils/history'

const DEREGIRSTERED = 10139 // 已被註銷

// create an axios instance
const $axios = axios.create({
  headers: {
    'True-Client-IP': '127.0.0.1',
  },
  baseURL: '/api',
})

// response interceptor
$axios.interceptors.response.use(async (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  const { data } = response
  switch (response.data.retStatus.StatusCode) {
    case 10000: {
      return data
    }
    case DEREGIRSTERED: {
      unstable_batchedUpdates(() => {
        dialogUseStore.getState().setDialog({ open: false })
        confirmDialogUseStore.getState().setConfirmDialog({ open: false })
      })
      await store.dispatch(logoutService())
      await history.push('/')
      const detail = {
        open: true,
        title: errorCodeMappingMsg[data.retStatus.StatusCode] || data.retStatus.StatusCode,
        content: '您已成功取消帳號註銷申請。於APP登入任意一次後，即可解鎖官網登入相關功能。',
        handleOnClose: () => history.push('/'),
      }
      unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog(detail)
      })
      throw data
    }
    case 20601:
    case 20603:
    case 10115:
    case 20604: {
      return data
    }
    default: {
      const needReload = !!needReloadStatusCodeList.includes(response.data.retStatus.StatusCode)
      if (needReload) {
        await store.dispatch(logoutService())
        await history.push('/')
        unstable_batchedUpdates(() => {
          dialogUseStore.getState().setDialog({ open: false })
          confirmDialogUseStore.getState().setConfirmDialog({ open: false })
        })
      }
      const detail = {
        open: true,
        // needReload,
        title: errorCodeMappingMsg[data.retStatus.StatusCode] ? '' : data.retStatus.StatusCode,
        content: errorCodeMappingMsg[data.retStatus.StatusCode] || errorCodeMappingMsg.default,
      }
      unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog(detail)
      })
      throw data
    }
  }
}, (error) => {
  const { status } = error.response
  let detail
  switch (status) {
    case 500: {
      detail = {
        open: true,
        title: '',
        content: '連線異常，請重新登入',
      }
      break
    }
    case 401: {
      detail = {
        open: true,
        title: '',
        content: '登入逾時，請重新登入',
        onConfirm: () => {
          window.location.reload()
        },
      }
      break
    }
    default: {
      // 如果後端沒處理就直接照axios格式
      detail = {
        open: true,
        title: '',
        content: error.message,
      }
    }
  }
  unstable_batchedUpdates(() => {
    alertDialogUseStore.getState().setAlertDialog(detail)
  })
  throw error
})

const $axiosCdn = axios.create({
  baseURL: process.env.REACT_APP_CDN_URL,
})

export default $axios
export { $axiosCdn }
