/* eslint-disable no-underscore-dangle */
import $script from 'scriptjs'

export default function initReCaptchaV2Invisible() {
  window.reCaptchaV2Invisible = (() => {
    let customCallback = null
    const sitekey = '6LfZ0Z4dAAAAAFUV-bh3evGuxMLLAp5DeDAtp55s'

    function downloadResource() {
      return new Promise((resolve, reject) => {
        if (!window.grecaptcha) {
          $script('https://www.google.com/recaptcha/api.js', () => {
            window.grecaptcha.ready(() => {
              resolve(true)
            })
          })
        } else {
          resolve(true)
        }
      })
    }

    async function renderReCaptcha() {
      if (!document.getElementById('reCaptchaV2') && window.___grecaptcha_cfg?.count === 0) {
        const reCaptchaClient = document.createElement('div')
        reCaptchaClient.id = 'reCaptchaV2'
        reCaptchaClient.setAttribute('class', 'g-recaptcha')
        reCaptchaClient.setAttribute('data-size', 'invisible')
        reCaptchaClient.setAttribute('data-badge', 'none')

        const targetDOM = document.getElementById('root')
        targetDOM.appendChild(reCaptchaClient)
        document.body.insertBefore(reCaptchaClient, targetDOM)

        window.grecaptcha.render('reCaptchaV2', {
          sitekey,
          callback(reToken) {
            try {
              customCallback(reToken)
              window.grecaptcha.reset()
            } catch (err) {
              //
            }
          },
        })
      }
      return true
    }

    async function init(appendOnElementID) {
      await downloadResource()
      await renderReCaptcha(appendOnElementID)
    }

    function execute(cb) {
      customCallback = cb
      window.scrollTo({ top: 0, behavior: 'smooth' })
      window.grecaptcha.execute()
    }
    return {
      init,
      execute,
    }
  })()
}
