import React, {
  useState, useEffect, useRef, useCallback, useContext
} from 'react'
import { UserInfoCustomizedStyles } from 'views/menuBar/desktop'
import { useHistory } from 'react-router-dom'
import Tooltip from '@material-ui/core/Tooltip'
import { useSelector, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import arrowImg from 'image/btn/arrow.png'
import question from 'image/icon/question-mark-in-dark-circle.svg'
import coin from 'image/icon/coin.png'
import memberInfoService from 'services/member/memberInfo'
import logoutService from 'services/auth/logout'
import IronCard from 'image/icon/VIPcard/Iron.png'
import BronzeCard from 'image/icon/VIPcard/Bronze.png'
import DiamondRedCard from 'image/icon/VIPcard/Diamond_red.png'
import DiamondCard from 'image/icon/VIPcard/Diamond.png'
import ExtremeCard from 'image/icon/VIPcard/Extreme.png'
import MonarchCard from 'image/icon/VIPcard/Monarch.png'
import GoldCard from 'image/icon/VIPcard/Gold.png'
import PlatinumCard from 'image/icon/VIPcard/Platinum.png'
import SilverCard from 'image/icon/VIPcard/Silver.png'
import clsx from 'clsx'
import styles from 'components/userInfo/common/userInfo.module.sass'
import CurrencyFormat from 'react-currency-format'
import transfer2goldenCoin from 'utils/transfer2goldenCoin'

const vipList = [
  { memberVIP: 1, img: IronCard },
  { memberVIP: 2, img: BronzeCard },
  { memberVIP: 3, img: SilverCard },
  { memberVIP: 4, img: GoldCard },
  { memberVIP: 5, img: PlatinumCard },
  { memberVIP: 6, img: DiamondRedCard },
  { memberVIP: 7, img: DiamondCard },
  { memberVIP: 8, img: ExtremeCard },
  { memberVIP: 9, img: MonarchCard },
  { memberVIP: 1001, img: DiamondCard },
]
// todo:百寶箱 pending
function UserInfo() {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    memberId, nickName, activeValue, memberVIP, memberBalance, vipValidTime, creditPoint,
  } = useSelector(state => state.member)
  const [openedCard, setOpenedCard] = useState(false)
  const [rightSideWidth, setRightSideWidth] = useState(0)
  const [cardWidth, setCardWidth] = useState(0)
  const placement = rightSideWidth < cardWidth ? 'bottom-center' : 'right-start'
  const userInfoRef = useRef(null)
  const customizedStyles = useContext(UserInfoCustomizedStyles)

  const vip = vipList.find(element => element.memberVIP === memberVIP)
  const vipImage = vip ? vip.img : undefined
  const isTurnOffDeposit = (process.env.REACT_APP_TURN_OFF_DEPOSIT === 'true')
  const memberPicUrl = `${process.env.REACT_APP_CDN_URL}/Game/Avatar/180/${memberId}.jpg`

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(memberInfoService())
      } catch (error) {
        //
      }
    }
    fetchData()
  }, [dispatch])

  useEffect(() => {
    async function fetchData() {
      try {
        await dispatch(memberInfoService())
      } catch (error) {
        //
      }
    }
    if (openedCard === true) {
      fetchData()
    }
  }, [openedCard, dispatch])

  const onRefChange = useCallback((ref) => {
    if (ref) {
      const width = window.innerWidth - userInfoRef.current.offsetLeft
        - userInfoRef.current.offsetWidth
      setRightSideWidth(width)
      setCardWidth(ref.offsetWidth)
    }
  }, [])

  async function handleLogout() {
    try {
      await dispatch(logoutService())
      await setOpenedCard(false)
      history.push('/')
    } catch (error) {
      //
    }
  }
  function handleCard() {
    setOpenedCard(!openedCard)
  }
  function handleRouter(pathname) {
    history.push(pathname)
  }
  function mappingVipTime() {
    if (vipValidTime > 9999999999999) {
      return '永久有效'
    }
    const date = new Date(vipValidTime - 1)
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
  }

  return (
    <div ref={userInfoRef}>
      <div
        type="button"
        aria-hidden="true"
        style={customizedStyles}
        className={styles.root}
        onClick={handleCard}
      >
        <div className={styles.isLoggedInMemberBG} />
        <div className={styles.isLoggedInWrapper}>
          <Avatar className={styles.avatar} alt="avatar" src={memberPicUrl} />
          <div className={`${styles.infoBlock} ${styles.isLoggedIn}`}>
            <div className={styles.activity}>
              活躍度：
              {activeValue}
            </div>
            <div className={styles.text}>{nickName}</div>
          </div>

        </div>
        <img
          className={clsx(styles.memberDetailInfoArrow, openedCard && styles.reverse)}
          aria-hidden="true"
          src={arrowImg}
          alt="arrow"
        />
        <Tooltip
          interactive
          PopperProps={{
            ref: onRefChange,
            popperOptions: {
              positionFixed: true,
              // modifiers: {
              //   preventOverflow: {
              //     enabled: true,
              //     boundariesElement: 'window', // where "window" is the boundary
              //   },
              // },
            },
          }}
          open={openedCard}
          placement={placement}
          classes={{
            tooltip: clsx(styles.card, placement === 'bottom-end' && styles.placementBottom),
            popper: styles.popper,
          }}
          title={
            openedCard && (
              <div className={styles.card}>
                <div className={styles.content}>
                  <div
                    className={clsx(styles.column, styles.desktopHidden, styles.primaryColor)}
                  >
                    活躍度：
                    {activeValue}
                  </div>
                  <div className={clsx(styles.underLine, styles.desktopHidden)} />
                  <div className={styles.column}>
                    <div className={styles.primaryColor}>
                      <img className={styles.pic} src={vipImage} alt="VIP" />
                      {mappingVipTime()}
                    </div>
                    <img
                      className={styles.pic}
                      src={question}
                      alt="question"
                      aria-hidden="true"
                      type="button"
                      onClick={() => { handleRouter('/vipIntro') }}
                    />
                  </div>
                  <div className={styles.underLine} />
                  <div className={styles.column}>
                    <div>
                      <img className={styles.pic} src={coin} alt="VIP" />
                      <CurrencyFormat
                        displayType="text"
                        thousandSeparator
                        value={transfer2goldenCoin(memberBalance)}
                      />

                    </div>
                  </div>
                  <div className={styles.underLine} />
                  {/* <div className={styles.column}>
                    保險箱：34321
                  </div>
                  <div className={styles.underLine} /> */}
                  <div className={styles.column}>
                    <CurrencyFormat
                      prefix="聚寶點數："
                      displayType="text"
                      thousandSeparator
                      value={creditPoint}
                    />
                  </div>
                  {!isTurnOffDeposit && (
                    <div
                      aria-hidden="true"
                      type="button"
                      className={styles.btn}
                      onClick={() => { handleRouter('/deposit') }}
                    >
                      立即儲值
                    </div>
                  )}
                </div>
                <div
                  type="button"
                  aria-hidden="true"
                  onClick={handleLogout}
                  className={styles.bottom}
                >
                  登出
                </div>
              </div>
            )
          }
        >
          <div className={styles.anchor} />
        </Tooltip>
      </div>
    </div>
  )
}

export default UserInfo
