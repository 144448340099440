import create from 'zustand'

const useStore = create((set, get) => ({
  infoDialog: {
    open: false,
    title: '',
    content: '',
    apiData: {},
    data: {},
    handleOnClose: () => { },
    component: () => null,
    state: null,
  },
  setInfoDialog: infoDialog => set({ infoDialog }),
  closeInfoDialog: () => {
    const { infoDialog } = get()
    if (infoDialog.handleOnClose) {
      infoDialog.handleOnClose()
    }
    set({ infoDialog: { open: false } })
  },
}))
export default useStore
