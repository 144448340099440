import { getYear, isThisYear } from 'date-fns'

const getCopyRightYear = () => (isThisYear(new Date('2021-06-11 00:00:00'))
  ? '2021'
  : `2021 ~ ${getYear(new Date())}`)

const footerInfo = {
  email: '電子信箱：service@gbaoonline.com',
  announcement: '本遊戲情節涉及牌類及益智娛樂類，非現金交易賭博，使用者請勿進行非法遊戲幣交易。注意使用時間、避免沉迷、遊戲虛擬情節請勿模仿。遊戲為免費使用，遊戲另供購買虛擬遊戲幣、物品等付費服務。限制行為能力人及無行為能力者，消費前應經法定代理人同意或代為之。',
  copyRight: `Copyright © ${getCopyRightYear()} JIXI TECHNOLOGY CO., LTD. All Rights Reserved. Website Ver. ${process.env.REACT_APP_Version}`,
}
export default footerInfo
