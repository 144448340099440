/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useRef, useState
} from 'react'
import { useDispatch } from 'react-redux'
import styles from 'components/dialog/common/dialog.module.sass'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import loginService from 'services/auth/login'
import {
  requiredMsg, account, password
} from 'constants/validation'
import IsMobile from 'utils/isMobile'
import Title from 'components/dialog/common/sub/title'
import Apple from 'image/login_apple.png'
import FB from 'image/login_FB.png'
import LINE from 'image/login_LINE.png'
import customizedStyles from 'views/login/common/login/login.module.sass'
import clsx from 'clsx'
import lineLogin from 'utils/auth/lineLogin'
import handleFbLogin from 'utils/auth/handleFbLogin'
import handleAppleSignIn from 'utils/auth/handleAppleSignIn'
import useStore from 'zustand/dialog'
import ForgetPassword from 'views/login/common/forgetPassword'
import useFullScreenBackdropStore from 'zustand/fullScreenBackdrop'
import getServerStatusService from 'services/system/getServerStatus'
import { PropTypes } from 'prop-types'

function Login(props) {
  const { passCheckMaintain } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const rememberAccountRef = useRef(false)
  const [isMaintenance, setIsMaintenance] = useState(false)
  const [startRender, setStartedRender] = useState(false)
  const [agreement, setAgreement] = useState(true)
  const { setDialog } = useStore()
  const { showFullScreenBackdrop, hideFullScreenBackdrop } = useFullScreenBackdropStore()
  const isMobile = IsMobile()
  const isMounted = useRef(false)
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    async function fetchData() {
      if (passCheckMaintain) {
        setStartedRender(true)
      } else {
        try {
          const data = await dispatch(getServerStatusService())
          // 0：正常營運、1：維護中
          await setStartedRender(true)
          if (data === 0) {
            setIsMaintenance(false)
          } else {
            setIsMaintenance(true)
          }
        } catch (error) {
          setStartedRender(true)
          //
        }
      }
    }
    fetchData()
  }, [dispatch, passCheckMaintain])

  useEffect(() => {
    if (isMaintenance === false && startRender === true) {
      const rememberAccount = JSON.parse(localStorage.getItem('rememberAccount'))
      if (rememberAccount) {
        if (rememberAccountRef) {
          rememberAccountRef.current.checked = true
          setValue('account', rememberAccount)
        }
      }
    }
  }, [setValue, isMaintenance, startRender])

  useEffect(() => {
    if (!isMounted.current && startRender) {
      isMounted.current = true
      window.reCaptchaV2Invisible.init()
    }
  }, [startRender])

  async function submit(data) {
    try {
      await showFullScreenBackdrop({})
      await dispatch(loginService({
        rememberAccount: rememberAccountRef.current.checked,
        ...data,
      }))
    } catch (error) {
      //
    } finally {
      hideFullScreenBackdrop()
    }
  }

  function ForgetPasswordBtn() {
    return (
      <button
        className={clsx(styles.btn, customizedStyles.forgetPasswordBtn)}
        type="button"
        onClick={() => { setDialog({ open: true, component: ForgetPassword }) }}
      >
        忘記密碼
      </button>
    )
  }
  function maintainTemplate() {
    return (
      <>
        <div className={styles.content}>
          <div className={styles.text}>
            系統維護中，請稍候再試。
          </div>
        </div>
      </>
    )
  }
  function loginTemplate() {
    return (
      <form id="loginForm" onSubmit={handleSubmit(submit)}>
        <Title title="會員登入" />
        <div className={styles.content}>
          <div
            aria-hidden="true"
            type="button"
            className={styles.center}
            onClick={async () => {
              try {
                if (agreement) {
                  await showFullScreenBackdrop({})
                  await setDialog({ open: false })
                  await handleAppleSignIn(dispatch)
                }
              } catch (error) {
                //
              } finally {
                hideFullScreenBackdrop()
              }
            }}
          >
            <img className={customizedStyles.socialMediaBtn} src={Apple} alt="Apple login" />
          </div>
          <div className={styles.center}>
            <img
              className={customizedStyles.socialMediaBtn}
              src={FB}
              aria-hidden="true"
              type="button"
              alt="FB login"
              onClick={async () => {
                try {
                  if (agreement) {
                    await showFullScreenBackdrop({})
                    await setDialog({ open: false })
                    await handleFbLogin(dispatch)
                  }
                } catch (error) {
                  //
                } finally {
                  hideFullScreenBackdrop()
                }
              }}

            />
          </div>
          <div className={styles.center}>
            <img
              onClick={async () => {
                try {
                  if (agreement) {
                    await showFullScreenBackdrop({})
                    await lineLogin()
                    history.push('/')
                  }
                } catch (error) {
                  //
                }
              }}
              aria-hidden="true"
              type="button"
              className={customizedStyles.socialMediaBtn}
              src={LINE}
              alt="LINE login"
            />
          </div>
          <div className={styles.form}>
            <div className={styles.label}>
              會員帳號
            </div>
            <div className={styles.input}>
              <input
                {...register('account', {
                  required: true,
                  pattern: account.pattern,
                })}
              />

            </div>
            <div className={styles.input}>
              {errors?.account?.type === 'required' && <div className="error">{requiredMsg}</div>}
              {errors?.account?.type === 'pattern' && (
                <div className="error">{account.msg}</div>
              )}
            </div>
            <div className={styles.label}>
              會員密碼
            </div>
            <div className={styles.input}>
              <input
                type="password"
                {...register('password', {
                  required: true,
                  pattern: password.pattern,
                })}
              />
              {!isMobile && <ForgetPasswordBtn />}
            </div>
            <div className={styles.input}>
              {errors?.password?.type === 'required' && <div className="error">{requiredMsg}</div>}
              {errors?.password?.type === 'pattern' && (
                <div className="error">{password.msg}</div>
              )}
            </div>

            <div className={`${styles.input} ${customizedStyles.checkInput}`}>
              <label htmlFor="rememberAccount">
                <input
                  ref={rememberAccountRef}
                  id="rememberAccount"
                  name="rememberAccount"
                  type="checkbox"
                />
                記住帳號資訊
              </label>
              {isMobile && <ForgetPasswordBtn />}
            </div>
            <div className={`${styles.input} ${customizedStyles.checkInput}`}>
              <label htmlFor="acceptTerms">
                <input
                  value={agreement}
                  checked={agreement}
                  id="acceptTerms"
                  name="acceptTerms"
                  type="checkbox"
                  {...register('acceptTerms', { required: true })}
                  onClick={(e) => { setAgreement(e.target.checked) }}
                />
                同意遵守「聚寶Online」
              </label>
              <Link to="/rightsDescription" target="_blank">使用者規章</Link>
              {errors?.acceptTerms?.type === 'required' && <div className="error">請勾選同意</div>}
            </div>
            <div />
          </div>
        </div>
        <button type="submit" className={clsx(styles.bottom, !agreement && styles.disabled)}>
          登入
        </button>
      </form>
    )
  }
  function handleContent() {
    if (!startRender) {
      return null
    }

    if (isMaintenance) {
      return maintainTemplate()
    }
    return loginTemplate()
  }
  return (
    <>
      {handleContent()}
    </>
  )
}

Login.propTypes = {
  passCheckMaintain: PropTypes.bool,
}

Login.defaultProps = {
  passCheckMaintain: false,
}

export default Login
