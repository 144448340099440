import React from 'react'
import { Dialog as MuiDialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styles from 'components/r18AlertDialog/common/r18AlertDialog.module.sass'
import useStore from 'zustand/r18AlertDialog'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function R18AlertDialog() {
  const dialog = useStore(state => state.r18AlertDialog)

  function selectComponent() {
    if (!dialog.component) {
      return (
        <>
          <div className={styles.content}>
            <div className={styles.text}>
              {dialog.content}
            </div>
          </div>
        </>
      )
    }
    return <dialog.component />
  }

  return (
    <MuiDialog
      maxWidth={false}
      TransitionComponent={Transition}
      open={dialog.open}
      classes={{
        paperWidthFalse: styles.paperWidthFalse,
      }}
    >
      <div className={styles.root}>
        {selectComponent()}
      </div>
    </MuiDialog>
  )
}

export default R18AlertDialog
