import $axios from 'utils/axios'

const system = {
  getCarouselApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/index/bannerList',
    })
    return data
  },
  toDownloadApi: async () => {
    const { data } = await $axios({
      method: 'post',
      url: '/store',
    })
    return data
  },
  getServerStatusApi: async () => {
    const { data } = await $axios({
      method: 'post',
      url: '/index/getServerStatus',
    })
    return data
  },
  getAppStoresApi: async () => {
    const { data } = await $axios({
      method: 'post',
      url: '/appStore',
    })
    return data
  },
  getPlayStoreApi: async () => {
    const { data } = await $axios({
      method: 'post',
      url: '/playStore',
    })
    return data
  },
}
export const {
  getCarouselApi,
  toDownloadApi,
  getServerStatusApi,
  getAppStoresApi,
  getPlayStoreApi,
} = system
export default system
