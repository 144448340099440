import { getGameListAPI } from 'api/externalGame'
import errorHandler from 'utils/errorHandler'

export default payload => async () => {
  try {
    return await getGameListAPI(payload)
  } catch (error) {
    return errorHandler(error)
  }
}
