import React from 'react'
import PropTypes from 'prop-types'
import UserInfo from 'components/userInfo/common'
import MainMenu from 'components/mainMenu/desktop'
import UserInfoCustomizedStyles from './stylesContext'
import styles from './menuBar.module.sass'

function MenuBar(props) {
  const { hide } = props
  return (
    <div className={styles.root}>
      <div className={styles.appBar}>
        <div className={styles.toolBar}>
          <div className={styles.toolbarItems}>
            <MainMenu customizedStyles={{ borderTopLeftRadius: '1rem', overflow: 'hidden' }} />
            <UserInfoCustomizedStyles.Provider value={{ borderTopRightRadius: '1rem' }}>
              {!hide && <UserInfo />}
            </UserInfoCustomizedStyles.Provider>
          </div>
        </div>
      </div>
    </div>
  )
}

MenuBar.propTypes = {
  hide: PropTypes.bool,
}

MenuBar.defaultProps = {
  hide: false,
}

export default MenuBar
