import create from 'zustand'

const useStore = create(set => ({
  fullScreenBackdrop: {
    open: false,
    content: '',
    component: null,
  },
  showFullScreenBackdrop: ({ content, component }) => set({
    fullScreenBackdrop: {
      open: true,
      content,
      component,
    },
  }),
  hideFullScreenBackdrop: () => set({
    fullScreenBackdrop: {
      open: false,
      content: '',
      component: null,
    },
  }),
}))
export default useStore
