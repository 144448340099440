import React from 'react'
import { Dialog as MuiDialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import styles from 'components/infoDialog/common/infoDialog.module.sass'
import Title from 'components/infoDialog/common/sub/title'
import useStore from 'zustand/infoDialog'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function InfoDialog() {
  const infoDialog = useStore(state => state.infoDialog)
  const { setInfoDialog } = useStore()

  function handleOnClose() {
    if (infoDialog.handleOnClose) {
      infoDialog.handleOnClose()
    }
    setInfoDialog({ open: false })
  }
  function selectComponent() {
    if (!infoDialog.component) {
      return (
        <>
          <Title title={infoDialog.title} />
          <div className={styles.content}>
            <div className={styles.text}>
              {infoDialog.content}
            </div>
          </div>
        </>
      )
    }
    return <infoDialog.component />
  }

  return (
    <MuiDialog
      maxWidth={false}
      TransitionComponent={Transition}
      open={infoDialog.open}
      onClose={() => { handleOnClose() }}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        {selectComponent()}
      </div>
    </MuiDialog>
  )
}

export default InfoDialog
