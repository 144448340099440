import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popover from '@material-ui/core/Popover'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import menuListData from 'constants/menuListData'
import styles from './mainMenu.module.sass'

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    width: props => props.btnWidth,
  },
})

function MainMenu(props) {
  const history = useHistory()
  const { customizedStyles } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const [btnHover, setBtnHover] = useState(null)
  const [openedPopover, setOpenedPopover] = useState(false)
  const [popoverList, setPopoverList] = useState([])
  const [btnWidth, setBtnWidth] = useState('100px')
  const [hoverOnMenu, setHoverOnMenu] = useState(false)

  const classes = useStyles({ btnWidth })

  const popoverEnter = () => {
    setOpenedPopover(true)
    setHoverOnMenu(true)
  }
  const popoverLeave = () => {
    setOpenedPopover(false)
    setBtnHover(null)
    setHoverOnMenu(false)
  }

  const handlePopoverClose = () => {
    setOpenedPopover(false)
    setHoverOnMenu(false)
  }

  const handlePopoverOpen = (data, { currentTarget }) => {
    setBtnHover(data.name)
    setHoverOnMenu(true)
    if (data.subItem) {
      setBtnWidth(currentTarget.clientWidth)
      setPopoverList(data.subItem)
      setAnchorEl(currentTarget)
      setOpenedPopover(true)
    }
  }

  const handleRouter = (data) => {
    setOpenedPopover(false)
    if (!data.subItem) {
      history.push(data.href)
    }
  }

  const isTurnOffDeposit = (process.env.REACT_APP_TURN_OFF_DEPOSIT === 'true')

  return (
    <div className={styles.menu} style={customizedStyles}>
      {menuListData.map((cur) => {
        if (isTurnOffDeposit && cur.isSensitive) {
          return null
        }
        return (
          <Button
            aria-haspopup="true"
            variant="text"
            className={`${styles.btn} ${hoverOnMenu && (cur.name === btnHover) ? styles.hover : ''}`}
            key={cur.name}
            onMouseEnter={e => handlePopoverOpen(cur, e)}
            onMouseLeave={handlePopoverClose}
            onClick={e => handleRouter(cur)}
          >
            {cur.label}
          </Button>
        )
      })}
      <Popover
        disableRestoreFocus
        className={classes.popover}
        classes={{
          paper: `${classes.popoverContent} ${styles.popoverList}`,
        }}
        open={openedPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          onMouseEnter: popoverEnter,
          onMouseLeave: popoverLeave,
        }}
        container={anchorEl}
      >
        {popoverList.map((data, i) => (
          <div
            type="button"
            aria-hidden="true"
            key={data.name}
            onClick={() => handleRouter(data)}
          >
            {data.label}
          </div>
        ))}
      </Popover>
    </div>
  )
}

MainMenu.propTypes = {
  customizedStyles: PropTypes.object,
}

MainMenu.defaultProps = {
  customizedStyles: {},
}

export default MainMenu
