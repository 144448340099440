/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styles from 'components/dialog/common/dialog.module.sass'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { requiredMsg, account, phone } from 'constants/validation'
import Title from 'components/dialog/common/sub/title'
import forgetPasswordService from 'services/auth/forgetPassword'
import { useDispatch } from 'react-redux'

function Step1({ setType, setApiData }) {
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  })

  async function sendSMS(data) {
    try {
      const result = await dispatch(forgetPasswordService(
        { phone: data.phone.slice(1), account: data.account }
      ))
      setApiData({ account: data.account, changePasswordRequestId: result.changePasswordRequestId })

      setType('STEP2')
    } catch (error) {
      //
    }
  }

  return (
    <form onSubmit={handleSubmit(sendSMS)}>
      <Title title="忘記密碼" />
      <div className={styles.content}>
        <div className={styles.text}>
          請輸入您的帳號及手機門號，系統將發給您簡訊驗證碼，即可下一步進行操作
        </div>
        <div className={styles.form}>
          <div className={styles.label}>
            會員帳號
          </div>
          <div className={styles.input}>
            <input
              {...register('account', {
                required: true,
                pattern: account.pattern,
              })}
            />
          </div>
          <div className={styles.input}>
            {errors?.account?.type === 'required' && <div className="error">{requiredMsg}</div>}
            {errors?.account?.type === 'pattern' && (
              <div className="error">{account.msg}</div>
            )}
          </div>
          <div className={styles.label}>
            手機門號
          </div>
          <div className={styles.input}>
            <input
              type="text"
              maxLength="10"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              {...register('phone', {
                required: true,
                pattern: phone.pattern,
              })}
            />
          </div>
          <div className={styles.input}>
            {errors?.phone?.type === 'required' && <div className="error">{requiredMsg}</div>}
            {errors?.phone?.type === 'pattern' && (
              <div className="error">{phone.msg}</div>
            )}
          </div>
        </div>
      </div>
      <button type="submit" disabled={!isDirty || !isValid} className={styles.bottom}>
        發送簡訊並下一步
      </button>
    </form>
  )
}
Step1.defaultProps = {
  setType: () => { },
  setApiData: () => { },
}
Step1.propTypes = {
  setType: PropTypes.func,
  setApiData: PropTypes.func,
}

export default Step1
