import { memberInfoApi } from 'api/member'
import { setInfo } from 'redux/member/slice'
import errorHandler from 'utils/errorHandler'

export default () => async (dispatch) => {
  try {
    const data = await memberInfoApi()
    await dispatch(setInfo(data))
  } catch (error) {
    errorHandler(error)
  }
}
