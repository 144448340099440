/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styles from 'components/dialog/common/dialog.module.sass'
import customizedStyles from 'views/login/common/verifyPhone/verifyPhone.module.sass'
import Title from 'components/dialog/common/sub/title'
import { useForm } from 'react-hook-form'
import { requiredMsg, phone, sms } from 'constants/validation'
import clsx from 'clsx'
import verifyPhoneService from 'services/auth/verifyPhone'
import bindPhoneService from 'services/auth/bindPhone'
import Countdown from 'components/countdown/common'
import useStore from 'zustand/dialog'

let phoneBindRequestId
function VerifyPhone() {
  const { setDialog } = useStore()

  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  })
  const [verification, setVerification] = useState(false)
  const [finishSMS, setFinishSMS] = useState(false)
  const isMounted = useRef(false)
  const watchPhone = watch('phone', '')

  async function submit(data) {
    try {
      await dispatch(verifyPhoneService(
        { verificationCode: data.sms, cellPhoneBindRequestId: phoneBindRequestId }
      ))
      setDialog({
        open: false,
      })
      window.location.reload()
    } catch (error) {
      //
    }
  }
  async function sendSMS() {
    if (!errors.phone) {
      try {
        const phoneValue = getValues('phone').slice(1)
        const data = await dispatch(bindPhoneService({ phone: phoneValue }))
        phoneBindRequestId = data.cellPhoneBindRequestId
        setFinishSMS(true)
      } catch (error) {
        //
      }
    }
  }
  function countdownFinish(e) {
    setFinishSMS(!finishSMS)
  }

  function step1Template() {
    return (
      <>
        <div className={styles.content}>
          <div className={styles.text}>
            手機綁定可以確保您的權益，是否進行驗證
          </div>
        </div>
        <div className={customizedStyles.bottom}>
          <button
            type="button"
            className={clsx(styles.bottom, customizedStyles.btn)}
            onClick={() => { setVerification(true) }}
          >
            馬上驗證
          </button>
          <button
            type="button"
            className={clsx(styles.bottom, customizedStyles.btn)}
            onClick={() => {
              setDialog({
                open: false,
              })
            }}
          >
            下一次再說
          </button>
        </div>
      </>
    )
  }
  function step2Template() {
    return (
      <form onSubmit={handleSubmit(submit)}>
        <div className={styles.content}>
          <div className={styles.text}>
            親愛的貴賓，手機綁定可以確保您的權益，請進行驗證。
          </div>
          <div className={styles.form}>
            <div className={styles.label}>
              手機門號
            </div>
            <div className={styles.input}>
              <input
                maxLength="10"
                type="text"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                {...register('phone', {
                  required: true,
                  pattern: phone.pattern,
                })}
                name="phone"
              />
            </div>
            <div className={styles.input}>
              {errors?.phone?.type === 'required' && <div className="error">{requiredMsg}</div>}
              {errors?.phone?.type === 'pattern' && (
                <div className="error">{phone.msg}</div>
              )}
            </div>
            <div className={styles.input}>
              <button
                className={styles.btn}
                type="button"
                disabled={(watchPhone === undefined || watchPhone === '') || finishSMS}
                onClick={sendSMS}
              >
                發送驗證碼
              </button>
            </div>
            <div className={styles.label}>
              簡訊驗證碼
            </div>
            <div className={styles.input}>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                maxLength="6"
                name="sms"
                {...register('sms', {
                  required: true,
                  pattern: sms.pattern,
                })}
              />
            </div>
            <div className={styles.input}>
              {errors?.sms?.type === 'required' && <div className="error">{requiredMsg}</div>}
              {errors?.sms?.type === 'pattern' && (
                <div className="error">{sms.msg}</div>
              )}
            </div>
            {finishSMS
              && (
                <div
                  className={clsx(styles.input, customizedStyles.msgText)}
                >
                  簡訊驗證碼已發送，請稍候。待
                  <Countdown time={60} handleFinish={countdownFinish} />
                  秒後方可重新發送簡訊驗證碼。
                </div>
              )}
          </div>
        </div>
        <button type="submit" disabled={!isDirty || !isValid} className={styles.bottom}>
          驗證
        </button>
      </form>
    )
  }
  function handleContent() {
    if (!verification) {
      return step1Template()
    }
    return step2Template()
  }

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      window.reCaptchaV2Invisible.init()
    }
  }, [])

  return (
    <>
      <Title title="手機驗證" />
      {handleContent()}
    </>
  )
}

export default VerifyPhone
