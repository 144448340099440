/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from 'components/dialog/common/dialog.module.sass'
import Title from 'components/dialog/common/sub/title'
import { useForm } from 'react-hook-form'
import { sms, requiredMsg } from 'constants/validation'
import verifyTrustDeviceService from 'services/auth/verifyTrustDevice'
import Countdown from 'components/countdown/common'
import loginService from 'services/auth/login'
import useStore from 'zustand/dialog'
import lineLogin from 'utils/auth/lineLogin'
import handleFbLogin from 'utils/auth/handleFbLogin'
import handleAppleSignIn from 'utils/auth/handleAppleSignIn'

function VerifyPhone() {
  const dialog = useStore(state => state.dialog)
  const { setDialog } = useStore()
  const {
    userData, loginType,
  } = useSelector(state => state.auth.trustDevice)

  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  })
  const [finishSMS, setFinishSMS] = useState(true)
  const [smsValidTimeout, setSmsValidTimeout] = useState(false)
  const [reset, setReset] = useState(false)

  async function submit(data) {
    function handleOnClose() {
      switch (loginType) {
        case 'NORMAL':
          return async () => {
            try {
              await dispatch(loginService(userData))
            } catch (error) {
              //
            }
          }
        case 'APPLE':
          return async () => { await handleAppleSignIn(dispatch) }
        case 'FB':
          return async () => { await handleFbLogin(dispatch) }
        case 'LINE':
          return async () => { await lineLogin() }
        default:
          return null
      }
    }
    try {
      await dispatch(verifyTrustDeviceService(
        { verificationCode: data.sms, trustDeviceBindRequestId: dialog.apiData.trustDeviceBindRequestId, action: 'CheckVerifyCode' }
      ))

      await setDialog({
        open: true,
        title: '綁定信賴裝置',
        content: '驗證成功',
        handleOnClose: handleOnClose(),
      })
    } catch (error) {
      //
    }
  }
  async function sendSMS() {
    try {
      setFinishSMS(!finishSMS)
      await dispatch(verifyTrustDeviceService({
        verificationCode: '', trustDeviceBindRequestId: dialog.apiData.trustDeviceBindRequestId, action: 'ResendVerifyCode',
      }))
      setSmsValidTimeout(false)
      await setReset(true)
      setReset(false)
    } catch (error) {
      //
    }
  }
  function countdownFinish(e) {
    setFinishSMS(!finishSMS)
  }
  function handleSmsValidTimeout(e) {
    setSmsValidTimeout(true)
  }
  function handleText(e) {
    if (smsValidTimeout) {
      return <div className={styles.text}>驗證碼逾時，請重新獲取</div>
    }
    return (
      <div className={styles.text}>
        請填入簡訊驗證碼, 此簡訊驗證碼有效期限
        {!reset && <Countdown time={600} handleFinish={handleSmsValidTimeout} />}
        秒
      </div>
    )
  }

  return (
    <>
      <Title title="綁定信賴裝置" />
      <form onSubmit={handleSubmit(submit)}>
        <div className={styles.content}>
          <div className={styles.text}>
            {handleText()}
          </div>
          <div className={styles.form}>
            <div className={styles.label}>
              簡訊驗證碼
            </div>
            <div className={styles.input}>
              <input
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                maxLength="6"
                name="sms"
                {...register('sms', {
                  required: true,
                  pattern: sms.pattern,
                })}
              />
            </div>
            <div className={styles.input}>
              {errors?.sms?.type === 'required' && <div className="error">{requiredMsg}</div>}
              {errors?.sms?.type === 'pattern' && (
                <div className="error">{sms.msg}</div>
              )}
            </div>
            <div className={styles.input}>
              <button
                className={styles.btn}
                type="button"
                disabled={finishSMS}
                onClick={sendSMS}
              >
                沒收到驗證碼?
                {finishSMS
                  && (
                    <span>
                      (
                      <Countdown time={60} handleFinish={countdownFinish} />
                      )
                    </span>
                  )}
              </button>
            </div>
          </div>
        </div>
        <button type="submit" disabled={!isDirty || !isValid} className={styles.bottom}>
          驗證
        </button>
      </form>
    </>
  )
}

export default VerifyPhone
