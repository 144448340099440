import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function Countdown({ time, handleFinish }) {
  const [seconds, setTime] = useState(time)

  const tick = () => {
    if (seconds === 0) { handleFinish() } else { setTime(seconds - 1) }
  }

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => clearInterval(timerID)
  })

  return (
    <span>
      {seconds}
    </span>
  )
}
Countdown.defaultProps = {
  time: 0,
  handleFinish: () => { },
}
Countdown.propTypes = {
  time: PropTypes.number,
  handleFinish: PropTypes.func,
}
export default Countdown
