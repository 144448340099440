import { bindPhoneApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'

export default phone => dispatch => new Promise((resolve, reject) => {
  const doBindPhone = async reToken => resolve(await bindPhoneApi({ reToken, ...phone }))
  try {
    return window.reCaptchaV2Invisible.execute(doBindPhone)
  } catch (error) {
    reject(error)
    return errorHandler(error)
  }
})
