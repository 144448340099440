import Cookies from 'universal-cookie'

const $cookies = new Cookies()
const cookies = {
  getCookie: name => $cookies.get(name),
  removeCookie: name => $cookies.remove(name),
}

export const { getCookie, removeCookie } = cookies

export default cookies
