import React, { useContext } from 'react'
import { UserInfoCustomizedStyles } from 'views/menuBar/desktop'
import { ReactComponent as AvatarIcon } from 'image/icon/member.svg'
import styles from 'components/userInfo/common/userInfo.module.sass'
import useStore from 'zustand/dialog'
import Login from 'views/login/common/login'

function UserInfo() {
  const { setDialog } = useStore()
  const customizedStyles = useContext(UserInfoCustomizedStyles)
  return (
    <div>
      <div
        style={customizedStyles}
        aria-hidden="true"
        onClick={() => { setDialog({ open: true, component: Login }) }}
        className={styles.root}
      >
        <div className={styles.notLoggedInMemberBG} />
        <div className={styles.notLoggedInWrapper}>
          <AvatarIcon className={styles.avatar} />
          <div className={styles.infoBlock}>
            <div className={styles.text}>
              會員登入
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default UserInfo
