export default async () => new Promise((resolve, reject) => {
  window.FB.getLoginStatus((response) => {
    if (response.status === 'connected') {
      const { accessToken } = response.authResponse
      resolve(accessToken)
    } else {
      try {
        window.FB.login((loginRes) => {
          if (loginRes.authResponse) {
            resolve(loginRes.authResponse.accessToken)
          } else {
            console.log('User cancelled login or did not fully authorize.')
            reject(new Error('FB_ERROR'))
          }
        })
      } catch (error) {
        reject(new Error('FB_ERROR'))
      }
    }
  })
})
