import React from 'react'
import MuiDialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import useStore from 'zustand/confirmDialog'
import clsx from 'clsx'
import Scrollbar from 'components/scrollbar/common'
import styles from './confirmDialog.module.sass'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

function ConfirmDialog() {
  const confirmDialog = useStore(state => state.confirmDialog)
  const { setConfirmDialog } = useStore()

  function selectComponent() {
    if (!confirmDialog.component) {
      return (
        <>
          <div className={styles.text}>
            {confirmDialog.content}
          </div>
        </>
      )
    }
    return confirmDialog.component
  }

  return (
    <MuiDialog
      maxWidth={false}
      scroll="paper"
      TransitionComponent={Transition}
      open={confirmDialog.open}
      classes={{
        paper: styles.paper,
      }}
    >
      <div className={styles.root}>
        <div className={styles.title}>
          {confirmDialog.title}
        </div>
        <div className={styles.content}>
          {confirmDialog.autoWidth && (
            selectComponent()
          )}
          {
            !confirmDialog.autoWidth && (
              <Scrollbar>
                <div className={styles.scrollbarContent}>
                  {selectComponent()}
                </div>

              </Scrollbar>
            )
          }
        </div>
        <div className={styles.functionFooter}>
          {confirmDialog.source === 'onlyConfirm'
            ? (
              <button
                className={clsx(styles.btn, styles.onlyConfirm)}
                type="button"
                onClick={() => { setConfirmDialog({ open: false }) }}
              >
                確認
              </button>
            )
            : (
              <>
                <button
                  className={clsx(styles.btn, styles.confirm)}
                  type="button"
                  onClick={() => {
                    setConfirmDialog({ open: false })
                    if (confirmDialog.onConfirm) {
                      confirmDialog.onConfirm()
                    }
                  }}
                >
                  {confirmDialog.source === 'firstSaveNote' ? '同意並儲值' : '確定' }
                </button>
                <button
                  className={clsx(styles.btn, styles.cancel)}
                  type="button"
                  onClick={() => { setConfirmDialog({ open: false }) }}
                >
                  取消
                </button>
              </>
            )}
        </div>
      </div>
    </MuiDialog>
  )
}

export default ConfirmDialog
