/* eslint-disable camelcase */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import loginByLineService from 'services/auth/loginByLine'
import alertDialogUseStore from 'zustand/alertDialog'
import { unstable_batchedUpdates } from 'react-dom'

function UseLineLogin() {
  const dispatch = useDispatch()
  const history = useHistory()
  const { search } = history.location
  const urlPathName = window.location.pathname
  const DEREGISTER_REDIRECT_TYPE = 'deregisterAccount'
  useEffect(() => {
    const lineState = JSON.parse(localStorage.getItem('lineState'))
    const code = new URLSearchParams(search).get('code')
    const state = new URLSearchParams(search).get('state')
    const lineLogin = search.includes('?loginByLine')
    let redirectType = ''
    if (urlPathName.includes(DEREGISTER_REDIRECT_TYPE)) {
      redirectType = DEREGISTER_REDIRECT_TYPE
    }
    async function fetchData() {
      try {
        if (code && state && lineLogin) {
          if (lineState !== state) {
            const detail = {
              open: true,
              title: 'LINE 登入錯誤',
              content: 'LINE 登入錯誤，請重新登入',
            }
            await unstable_batchedUpdates(() => {
              alertDialogUseStore.getState().setAlertDialog(detail)
            })
          } else {
            await dispatch(loginByLineService({ code, redirectType }))
          }
          if (redirectType === DEREGISTER_REDIRECT_TYPE) {
            history.push(`/${DEREGISTER_REDIRECT_TYPE}`)
          } else {
            history.push('/')
          }
        }
      } catch (error) {
        //
      }
    }
    fetchData()
  }, [dispatch, history, search, urlPathName])
}

export default UseLineLogin
