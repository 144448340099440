import {
  Drawer as MuiDrawer
} from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import facebookIcon from 'image/icon/FB.svg'
import lineIcon from 'image/icon/line.svg'
import Collapse from '@material-ui/core/Collapse'
import clsx from 'clsx'
import youtubeIcon from 'image/icon/youtube.svg'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import listData from 'constants/menuListData'
import arrow from 'image/btn/arrow_1.png'
import PropTypes from 'prop-types'
import styles from './drawer.module.sass'
// todo add social media href
function Drawer({ openDrawer, setOpenedDrawer }) {
  const [openSubItem, setOpenedSubItem] = useState(false)
  const [clickItem, setClickItem] = useState(null)
  const history = useHistory()
  const isTurnOffDeposit = (process.env.REACT_APP_TURN_OFF_DEPOSIT === 'true')

  const handleClick = (item) => {
    setClickItem(item.name)
    if (!item.subItem) {
      history.push(item.href)
      setOpenedDrawer(false)
      return
    }
    if (item.name === clickItem) {
      setOpenedSubItem(!openSubItem)
    } else {
      setOpenedSubItem(true)
    }
  }

  const SubItem = (item) => {
    const { name, subItem } = item
    if (name === clickItem && subItem) {
      return (
        <Collapse in={openSubItem}>
          <List className={styles.subListItems} component="div">
            {
              subItem.map(data => (
                <ListItem button key={data.name}>
                  <div
                    type="button"
                    aria-hidden="true"
                    className={styles.subListItem}
                    key={data.name}
                    onClick={() => handleClick(data)}
                  >
                    {data.label}
                  </div>
                </ListItem>

              ))
            }
          </List>
        </Collapse>
      )
    }
    return null
  }

  return (
    <MuiDrawer
      classes={{
        paper: styles.paper,
      }}
      anchor="right"
      open={openDrawer}
      onClose={() => { setOpenedDrawer(false) }}
    >
      <div className={styles.drawer}>
        <div className={styles.list}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {listData.map((data) => {
              if (isTurnOffDeposit && data.isSensitive) {
                return null
              }
              return (
                <div
                  key={data.name}
                >
                  <ListItem button key={data.name} className={styles.listItems}>
                    <div
                      type="button"
                      aria-hidden="true"
                      key={data.name}
                      className={styles.listItem}
                      onClick={() => handleClick(data)}
                    >
                      <img className={styles.icon} src={data.icon} alt={data.name} />

                      <div className={styles.label}>{data.label}</div>
                      {data.subItem && (
                        <img
                          className={clsx(styles.arrowBtn,
                            data.name === clickItem && openSubItem && styles.reverse)}
                          src={arrow}
                          alt={data.name}
                        />
                      )}
                    </div>

                  </ListItem>
                  <SubItem {...data} />
                </div>

              )
            })}
          </List>
        </div>
        <div className={styles.mediaGroup}>
          <a href={process.env.REACT_APP_FB_LINK} target="_blank" rel="noreferrer">
            <div className={styles.facebook}>
              <img className={styles.icon} src={facebookIcon} alt="facebook" />
              粉絲專頁
            </div>
          </a>
          <a href={process.env.REACT_APP_YOUTUBE_LINK} target="_blank" rel="noreferrer">
            <div className={styles.youtube}>
              <img className={styles.icon} src={youtubeIcon} alt="youtube" />
              Youtube
            </div>
          </a>
          <a href={process.env.REACT_APP_LINE_LINK} target="_blank" rel="noreferrer">
            <div className={styles.line}>
              <img className={styles.icon} src={lineIcon} alt="line" />
              LINE
            </div>
          </a>
        </div>
      </div>

    </MuiDrawer>

  )
}
Drawer.propTypes = {
  openDrawer: PropTypes.bool,
  setOpenedDrawer: PropTypes.func,
}

Drawer.defaultProps = {
  openDrawer: false,
  setOpenedDrawer: () => { },
}

export default Drawer
