import { loginApi } from 'api/auth'
import { login, setTrustDevice } from 'redux/auth/slice'
import handleBindingPhone from 'utils/auth/handleBindingPhone'
import handleNeedBindTrustDevice from 'utils/auth/handleNeedBindTrustDevice'
import errorHandler from 'utils/errorHandler'
import { memberInfoApi } from 'api/member'
// eslint-disable-next-line camelcase
import checkIsCoolingPeriod from 'utils/checkIsCoolingPeriod'

const handleRememberAccount = async (user) => {
  if (user.rememberAccount) {
    localStorage.setItem('rememberAccount', JSON.stringify(user.account))
  } else {
    localStorage.removeItem('rememberAccount')
  }
}

export default user => async (dispatch) => {
  const doLogin = async (reToken) => {
    await dispatch(setTrustDevice({ userData: user, loginType: 'NORMAL' }))
    const result = await loginApi({ reToken, ...user })
    if (result && result.needBindTrustDevice) {
      await handleNeedBindTrustDevice(result)
    } else {
      await dispatch(login())
      await handleRememberAccount(user)
      const memberInfoData = await memberInfoApi()
      await handleBindingPhone(memberInfoData, dispatch)
      checkIsCoolingPeriod(result)
    }
  }
  try {
    window.reCaptchaV2Invisible.execute(doLogin)
  } catch (error) {
    errorHandler(error)
  }
}
