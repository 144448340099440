import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import UserInfo from 'components/userInfo/common'
import Logo from 'components/logo/common'
import MainMenu from 'components/mainMenu/desktop'
import clsx from 'clsx'
import styles from './header.module.sass'

const HEADER_Y_SHOW_VALUE = 50
const HEADER_BG_SCROLL_ANIMATION_URL = ['/']

function Header() {
  const location = useLocation()
  const [bodyEdgeLimit, setBodyEdgeLimit] = useState(
    !HEADER_BG_SCROLL_ANIMATION_URL.includes(location.pathname)
  )

  const onScroll = () => {
    setBodyEdgeLimit(document.documentElement.scrollTop > HEADER_Y_SHOW_VALUE)
  }

  useEffect(() => {
    if (HEADER_BG_SCROLL_ANIMATION_URL.includes(location.pathname)) {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [location.pathname])

  return (
    <div className={styles.root}>
      <div className={clsx(styles.toolbar)}>
        <div className={styles.leftTrack} />
        <div className={styles.centerContent}>
          <div className={styles.headerContent}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.toolbarItems}>
              <MainMenu />
              <UserInfo />
            </div>
          </div>
        </div>
        <div className={styles.rightTrack} />
        <div className={clsx(styles.headerBackground, bodyEdgeLimit && styles.isActive)} />
      </div>
    </div>

  )
}

export default Header
