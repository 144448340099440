import React from 'react'
import styles from 'components/dialog/common/dialog.module.sass'
import PropTypes from 'prop-types'
import CloseBtn from 'image/Button_Close.png'
import useStore from 'zustand/dialog'

function Title({ title }) {
  const dialog = useStore(state => state.dialog)
  const { setDialog } = useStore()

  function handleOnClose() {
    if (dialog.handleOnClose) {
      dialog.handleOnClose()
    }
    setDialog({ open: false })
  }
  return (
    <>
      <div className={styles.title}>
        {title}
        <img
          aria-hidden="true"
          type="button"
          onClick={() => { handleOnClose() }}
          className={styles.closeBtn}
          src={CloseBtn}
          alt="CloseBtn"
        />
      </div>

    </>
  )
}

Title.propTypes = {
  title: PropTypes.string,
}

Title.defaultProps = {
  title: '',
}

export default Title
