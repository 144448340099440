// http://conf.yilegaming.com:8090/pages/viewpage.action?pageId=102696490
const errorCode = {
  'default': '連線異常。',
  6248: '門號開啟阻擋商業簡訊服務，請聯繫電信供應商(1)',
  6253: '門號開啟阻擋商業簡訊服務，請聯繫電信供應商(2)',
  118001: '登入資訊異常，請重新登入。',
  200109: '密碼登入失敗。',
  10101: '帳號或密碼輸入錯誤，請重新輸入',
  10102: '登入失敗，若您尚未註冊請至官網下載聚寶online APP。',
  10105: '連線異常，請重新登入',
  10111: '驗證碼逾時，請重新獲取',
  10112: '變更密碼失敗',
  10113: '驗證碼輸入錯誤',
  10114: '綁定失敗',
  10116: '簡訊驗證功能已鎖，請聯繫客服協助處理。',
  10120: '親愛的玩家您好，您的遊戲帳號目前被系統停權中，如有任何疑問請上官方網站查詢，謝謝。',
  10126: '登入過期，請重新登入',
  10127: '登入失敗',
  10129: '此帳號在不同裝置登入',
  10130: '伺服器維護中',
  10138: '親愛的玩家您好，您的遊戲帳號目前被系統停權中，如有任何疑問請上官方網站查詢，謝謝。',
  10139: '該帳號已註銷',
  10329: '裝置已達綁定上限',
  10330: '該Facebook帳號已綁定遊戲資料，請使用其他Facebook帳號進行綁定。',
  10332: '帳號已被使用',
  10339: '密碼須為6~12個英文+數字的組合，請重新輸入',
  10344: '此暱稱已有其他玩家使用，請重新輸入其他暱稱',
  10340: '輸入資料有誤，請重新確認',
  10345: '舊密碼輸入錯誤',
  10346: '驗證碼請求次數過多，簡訊驗證功能已鎖，請聯繫客服協助處理。',
  10347: '簡訊驗證錯誤超過五次，請重新獲取簡訊驗證',
  10349: '該Line帳號已綁定遊戲資料，請使用其他Line帳號進行綁定。',
  10401: '傳送失敗請稍後再試',
  10402: '傳送失敗請稍後再試',
  10403: '重複發送回報時間過於頻繁，如有其他問題請稍候再進行回報',
  12201: '連結獎勵錯誤',
  12202: '連結獎勵已領取',
  12203: '連結獎勵已過期',
  12204: '連結獎勵數量不足',
  12502: '機台已滿',
  12602: '可贈禮數量不足',
  12603: '今日可送禮次數已達上限\n請於明日再進行送禮',
  12612: '簡訊功能已鎖，請連繫客服協助處理',
  200501: '玩家帳號不存在',
  200502: '玩家累積押注查詢錯誤',
  200503: '玩家帳號需填入 12 個字元',
  200504: '目前無開放此活動查詢',

  // myCard error code
  7382: '點數卡已經被儲值或卡號或密碼有誤',
  7375: '驗證成功',
  7383: '卡號或密碼有誤',
  7384: '寫入點卡資料庫發生問題',
  7390: '取得訂單資料發生問題',
  7395: 'MyCard 回應內容無法完成購買',
  7396: '主機忙線中,請稍後再次進行操作,若仍無法順利購買請通知客服人員協助您完成',
  7053: '單次購買數量超出限制',
  7397: '付款方式無效或不允許',
  7399: '未知錯誤',
  7064: '商品交易超出儲值金額上限',
  7065: '商品購買次數超過上限',
  6613: '商品購買權限檢查失敗',
  10612: '兌換失敗',

  // 產包開通 error code
  200401: '查無此序號',
  200402: '此序號已註銷，如有問題請聯繫客服',
  200403: '此序號已儲值使用，如有問題請聯繫客服',
  200404: '此序號已失效，如有問題請聯繫客服',
  200405: '此序號已被使用',
  6611: '無效的序號',
  7008: '無效的序號',
}

export default errorCode
