import $axios from 'utils/axios'

const member = {
  memberInfoApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/member/info',
    })
    return data
  },
  modifyPasswordApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/member/modifyPassword',
      data: {
        oldPassword: payload.oldPassword,
        newPassword: payload.newPassword,
      },
    })
    return data
  },
  getPassbookApi: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/member/passbook',
      data: {
        page: payload.page,
        limit: payload.limit,
      },
    })
    return data
  },
}
export const { memberInfoApi, modifyPasswordApi, getPassbookApi } = member
export default member
