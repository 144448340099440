import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { FacebookShareButton, LineShareButton } from 'react-share'
import facebookIcon from 'image/icon/FB.svg'
import lineIcon from 'image/icon/line.svg'
import linkIcon from 'image/icon/link.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Tip from 'components/tip/common'
import styles from './shareButtonGroup.module.sass'

function ShareButtonGroup({ articleURL, iconMode }) {
  const [openCopiedTooltip, setOpenCopiedTooltip] = useState(false)

  const copied = () => {
    setOpenCopiedTooltip(true)
  }

  return (
    <div className={styles.root}>
      <FacebookShareButton url={articleURL}>
        <div className={styles.shareBtn}>
          <div className={styles.facebookBtn}>
            <img src={facebookIcon} alt="Facebook" />
            <div className={styles.text}>分享</div>
          </div>
        </div>
      </FacebookShareButton>
      <LineShareButton url={articleURL}>
        <div className={styles.shareBtn}>
          <div className={styles.lineBtn}>
            <img src={lineIcon} alt="Line" />
            <div className={styles.text}>傳送</div>
          </div>
        </div>
      </LineShareButton>
      <CopyToClipboard
        text={articleURL}
        onCopy={copied}
      >
        <div className={styles.shareBtn}>
          <Tip
            open={openCopiedTooltip}
            title="複製完成!"
            setOpen={setOpenCopiedTooltip}
          >
            <div className={styles.copyLinkBtn}>
              <img src={linkIcon} alt="Line" />
              <div className={styles.text}>複製</div>
            </div>
          </Tip>
        </div>
      </CopyToClipboard>
    </div>
  )
}

ShareButtonGroup.propTypes = {
  articleURL: PropTypes.string,
  iconMode: PropTypes.bool,
}

ShareButtonGroup.defaultProps = {
  articleURL: window.location.hostname,
  iconMode: false,
}

export default ShareButtonGroup
