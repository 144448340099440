import create from 'zustand'

const useStore = create(set => ({
  confirmDialog: {
    open: false,
    title: '',
    content: '',
    component: () => null,
    onConfirm: () => {},
    source: '',
  },
  setConfirmDialog: confirmDialog => set({ confirmDialog }),
}))
export default useStore
