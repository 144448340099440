import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Fade from '@material-ui/core/Fade'
import styles from './tip.module.sass'

function Tip(props) {
  const {
    children, open, title, placement, timeout, setOpen,
  } = props

  useEffect(() => {
    if (setOpen) {
      setTimeout(() => { setOpen(false) }, timeout)
    }
  }, [open, setOpen, timeout])

  return (
    <div className={styles.root}>
      <Tooltip
        open={open}
        classes={{ tooltip: styles.tooltip, arrow: styles.tooltipArrow }}
        title={title}
        placement={placement}
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: { enter: 0, exit: 2000 } }}
      >
        {children}
      </Tooltip>
    </div>
  )
}

Tip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  open: PropTypes.bool,
  placement: PropTypes.string,
  title: PropTypes.string,
  timeout: PropTypes.number,
  setOpen: PropTypes.func,
}

Tip.defaultProps = {
  children: [],
  open: false,
  placement: 'top',
  title: '',
  timeout: 1000,
  setOpen: () => { },
}

export default Tip
