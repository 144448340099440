import React from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from 'theme/desktop/muiTheme'
import { Switch, Route } from 'react-router-dom'
import routes from 'router/app'
import styles from './layout.module.sass'

function Layout() {
  return (
    <ThemeProvider theme={theme}>
      <div className={styles.root}>
        <Switch>
          {routes().map((route, i) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          ))}
        </Switch>

      </div>
    </ThemeProvider>
  )
}
export default Layout
