import PropTypes from 'prop-types'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import styles from './loadingBackdrop.module.sass'

const useStyles = makeStyles(theme => ({
  backdrop: {
    position: 'absolute',
    zIndex: 1099,
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 32%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.5) 68%, rgba(0,0,0,0) 100%)',
  },
}))

function LoadingBackdrop(props) {
  const { isLoading } = props
  const classes = useStyles()
  return (
    <Backdrop className={classes.backdrop} open={isLoading}>
      <div className={styles.loadingImg} />
    </Backdrop>
  )
}

LoadingBackdrop.propTypes = {
  isLoading: PropTypes.bool,
}

LoadingBackdrop.defaultProps = {
  isLoading: false,
}

export default LoadingBackdrop
