// eslint-disable-next-line import/no-cycle
import { logoutApi } from 'api/auth'
import { logout } from 'redux/auth/slice'
import { removeCookie } from 'utils/cookie'
import errorHandler from 'utils/errorHandler'

export default () => async (dispatch) => {
  try {
    await logoutApi()
    await dispatch(logout())
    removeCookie('MemberId')
  } catch (error) {
    errorHandler(error)
  }
}
