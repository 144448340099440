import { changePasswordApi } from 'api/auth'
import errorHandler from 'utils/errorHandler'

export default data => async (dispatch) => {
  try {
    await changePasswordApi(data)
  } catch (error) {
    errorHandler(error)
  }
}
