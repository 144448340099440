import React from 'react'
import { Link } from 'react-router-dom'
import styles from './logo.module.sass'

function Logo() {
  return (
    <div className={styles.root}>
      <Link to="/">
        <div className={styles.GBaoLogo}>
          <div className={styles.GBao} />
          <div className={styles.GBaoBase} />
          <div className={styles.neonLight} />
          <div className={styles.goldIngot} />
        </div>
      </Link>
    </div>
  )
}

export default React.memo(Logo)
