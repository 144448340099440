import $axios from 'utils/axios'

const externalGame = {
  getBetInfoAPI: async (payload) => {
    const { data } = await $axios({
      method: 'post',
      url: '/externalGame/getBetInfo',
      data: {
        memberId: payload?.memberId,
        eventId: payload?.eventId,
      },
    })
    return data
  },
  getGameListAPI: async (payload) => {
    const { data } = await $axios({
      method: 'get',
      url: `/externalGame/getGameList/${payload?.eventId}`,
    })
    return data
  },
}

export const { getBetInfoAPI, getGameListAPI } = externalGame
export default externalGame
