/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { useDispatch } from 'react-redux'
import styles from 'components/dialog/common/dialog.module.sass'
import { useForm } from 'react-hook-form'
import Countdown from 'components/countdown/common'
import { requiredMsg, password, sms } from 'constants/validation'
import PropTypes from 'prop-types'
import Title from 'components/dialog/common/sub/title'
import changePasswordService from 'services/auth/changePassword'
import useStore from 'zustand/dialog'
import alertDialogUseStore from 'zustand/alertDialog'

function Step2({ setType, apiData }) {
  const { setDialog } = useStore()
  const { setAlertDialog } = alertDialogUseStore()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  })
  const dispatch = useDispatch()

  async function submit(data) {
    try {
      await dispatch(changePasswordService(
        {
          newPassword: data.password, account: apiData.account, verificationCode: data.sms, changePasswordRequestId: apiData.changePasswordRequestId, action: 'CheckVerifyCode',
        }
      ))
      setType('SUCCESS')
    } catch (error) {
      //
    }
  }
  async function countdownFinish(e) {
    const detail = {
      open: true,
      title: '簡訊驗證逾時',
      content: '您的簡訊驗證碼已失效，請重新輸入門號進行簡訊驗證流程',
    }
    await setAlertDialog(detail)

    setDialog({
      open: false,
    })
  }
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Title title="修改密碼" />
      <div className={styles.content}>
        <div className={styles.text}>
          親愛的貴賓，如需修改密碼請進行驗證，倒數
          <Countdown time={600} handleFinish={countdownFinish} />
          秒
        </div>
        <div className={styles.form}>
          <div className={styles.label}>
            簡訊驗證碼
          </div>
          <div className={styles.input}>
            <input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              maxLength="6"
              name="sms"
              {...register('sms', {
                required: true,
                pattern: sms.pattern,
              })}
            />
          </div>
          <div className={styles.input}>
            {errors?.sms?.type === 'required' && <div className="error">{requiredMsg}</div>}
            {errors?.sms?.type === 'pattern' && (
              <div className="error">{sms.msg}</div>
            )}
          </div>
          <div className={styles.label}>
            會員密碼
          </div>
          <div className={styles.input}>
            <input
              type="password"
              name="password"
              {...register('password', {
                required: true,
                pattern: password.pattern,
              })}
            />
          </div>
          <div className={styles.input}>
            {errors?.password?.type === 'required' && <div className="error">{requiredMsg}</div>}
            {errors?.password?.type === 'pattern' && (
              <div className="error">{password.msg}</div>
            )}
          </div>
          <div className={styles.label}>
            確認密碼
          </div>
          <div className={styles.input}>
            <input
              name="confirmPassword"
              type="password"
              {...register('confirmPassword', {
                required: true,
                validate: value => value === watch('password') || '需與會員密碼一致',
              })}
            />
          </div>
          <div className={styles.input}>
            {errors?.confirmPassword?.type === 'required'
              && <div className="error">{requiredMsg}</div>}
            {errors?.confirmPassword?.type === 'validate'
              && <div className="error">{errors.confirmPassword.message}</div>}
          </div>
        </div>
      </div>
      <button type="submit" className={styles.bottom}>
        更新密碼
      </button>
    </form>
  )
}
Step2.defaultProps = {
  setType: () => { },
  apiData: {},
}
Step2.propTypes = {
  setType: PropTypes.func,
  apiData: PropTypes.object,
}
export default Step2
