import create from 'zustand'

const useStore = create(set => ({
  alertDialog: {
    open: false,
    title: '',
    content: '',
    // needReload: false,
    component: () => null,
    handleOnClose: () => { },
    onConfirm: () => {},
    source: '',
  },
  setAlertDialog: alertDialog => set({ alertDialog }),
}))
export default useStore
