import React, { useState, useRef, useEffect } from 'react'
// import Footer from 'views/footer/mobile'
import PropTypes from 'prop-types'
import FbMsg from 'components/fbMsg/'
import MsgIcon from 'components/msgIcon'
import { useLocation } from 'react-router-dom'
import extraActivityRouterName from 'constants/extraActivityRouterName'

function HandleFB({ showHint }) {
  const [isIOS, setIsIOS] = useState(false)
  const isMounted = useRef(false)
  const location = useLocation()
  const notExtraActivityRouterName = extraActivityRouterName
    .filter(cur => location.pathname.startsWith(cur))?.length === 0
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true
      const { userAgent } = navigator
      const ios = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios
      if (ios) setIsIOS(true)
    }
  }, [])

  return (
    <>
      {(notExtraActivityRouterName && isIOS) ? <MsgIcon showHint={showHint} /> : (notExtraActivityRouterName && !isIOS) ? <FbMsg /> : ''}
    </>
  )
}

HandleFB.propTypes = {
  showHint: PropTypes.bool,

}

HandleFB.defaultProps = {
  showHint: null,
}
export default HandleFB
