import React, { memo } from 'react'

// ** import third party
import PropTypes from 'prop-types'

// ** import config
import { REBATE_TYPE } from 'constants/externalGame/rebateConfig'

import styles from './titleBar.module.sass'

function TitleBar(props) {
  const {
    type, title, startTime, endTime,
  } = props

  return (
    <div className={styles.root}>
      {type === REBATE_TYPE.EXTERNAL && (
        <>
          <h1 className={styles.title}>限時返水活動</h1>
          <h3 className={styles.subtitle}>{title}</h3>
        </>
      )}
      {type === REBATE_TYPE.INTERNAL && (
        <h1 className={styles.title}>{title}</h1>
      )}
      <p className={styles.activityDate}>{`${startTime} ~ ${endTime}`}</p>
    </div>
  )
}

export default memo(TitleBar)

TitleBar.propTypes = {
  type: PropTypes.number,
  title: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
}
TitleBar.defaultProps = {
  type: REBATE_TYPE.EXTERNAL,
  title: '',
  startTime: '',
  endTime: '',
}
