import { getPageApi } from 'api/article'
import errorHandler from 'utils/errorHandler'

export default payload => async (dispatch) => {
  try {
    return await getPageApi(payload)
  } catch (error) {
    return errorHandler(error)
  }
}
