import React from 'react'
import styles from 'components/dialog/common/dialog.module.sass'
import Title from 'components/dialog/common/sub/title'
import customizedStyles from 'views/login/common/forgetPassword/forgetPassword.module.sass'

function Success() {
  return (
    <>
      <Title title="更新密碼" />
      <div className={styles.content}>
        <div className={customizedStyles.text}>
          您的密碼已修改成功！
        </div>
        <div className={styles.text}>
          請使用新密碼登入
        </div>
      </div>
    </>
  )
}

export default Success
