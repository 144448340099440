import fbLogin from 'utils/auth/fbLogin'
import loginByFbService from 'services/auth/loginByFb'
import alertDialogUseStore from 'zustand/alertDialog'
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import useFullScreenBackdropStore from 'zustand/fullScreenBackdrop'

export default async (dispatch) => {
  try {
    const accessToken = await fbLogin()
    await dispatch(loginByFbService({ accessToken }))
  } catch (error) {
    unstable_batchedUpdates(() => {
      useFullScreenBackdropStore.getState().hideFullScreenBackdrop()
    })
    if (error.message === 'FB_ERROR') {
      unstable_batchedUpdates(() => {
        alertDialogUseStore.getState().setAlertDialog({
          open: true,
          title: '',
          content: 'FACEBOOK 登入錯誤',
        })
      })
    }
  }
}
