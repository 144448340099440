// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from 'react-dom'
import alertDialogUseStore from 'zustand/alertDialog'

export default function checkIsCoolingPeriod(result) {
  if (result.isCoolingPeriod) {
    const detail = {
      open: true,
      title: '',
      content: '你已取消帳號註銷',
    }
    unstable_batchedUpdates(() => {
      alertDialogUseStore.getState().setAlertDialog(detail)
    })
  }
}
